import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

type FormValues = {
  industry: string;
  subSector: string;
  stage: string[];
  technologyPreferences: string[];
  businessModel: string[];
  geographicFocus: string[];
  revenueMargins: string[];
  ageOfStartup: string[];
};

const initialValues: FormValues = {
  industry: '',
  subSector: '',
  stage: [],
  technologyPreferences: [],
  businessModel: [],
  geographicFocus: [],
  revenueMargins: [],
  ageOfStartup: [],
};

const registrationSchema = Yup.object().shape({
  industry: Yup.string().required('Industry is required'),
  subSector: Yup.string().required('Sub-sector is required'),
  stage: Yup.array().required('At least one stage is required').min(1, 'At least one stage is required'),
  technologyPreferences: Yup.array().required('At least one technology preference is required').min(1, 'At least one technology preference is required'),
  businessModel: Yup.array().required('At least one business model is required').min(1, 'At least one business model is required'),
  geographicFocus: Yup.array().required('At least one geographic focus is required').min(1, 'At least one geographic focus is required'),
  revenueMargins: Yup.array().required('At least one revenue margin is required').min(1, 'At least one revenue margin is required'),
  ageOfStartup: Yup.array().required('At least one age of startup is required').min(1, 'At least one age of startup is required'),
});

interface StepProps {
  setStepIncomplete: (flag: boolean) => void;
  responseError: string;
  responseSuccess: string;
  setFormikValues: (values: any) => void;
  stepper: any;
  loading: boolean;
  investorCriteria: any;
  attemptedNextStep: boolean;
}

const Step1Investor: React.FC<StepProps> = ({
  setStepIncomplete,
  responseError,
  responseSuccess,
  setFormikValues,
  stepper,
  loading,
  investorCriteria,
  attemptedNextStep,
}) => {
  const [isValid, setIsValid] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<Record<keyof FormValues, boolean>>({
    industry: false,
    subSector: false,
    stage: false,
    technologyPreferences: false,
    businessModel: false,
    geographicFocus: false,
    revenueMargins: false,
    ageOfStartup: false,
  });

  const dropdownRefs = {
    stage: useRef<HTMLDivElement>(null),
    technologyPreferences: useRef<HTMLDivElement>(null),
    businessModel: useRef<HTMLDivElement>(null),
    geographicFocus: useRef<HTMLDivElement>(null),
    revenueMargins: useRef<HTMLDivElement>(null),
    ageOfStartup: useRef<HTMLDivElement>(null),
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: registrationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

    },
  });

  useEffect(() => {
    setFormikValues(formik.values);

    Object.keys(formik.values).forEach((field) => {
      //formik.setFieldTouched(field as keyof FormValues, true);
      formik.validateField(field);
    });

    validateAllFields();
  }, [formik.values, setFormikValues]);

  useEffect(() => {
    if (attemptedNextStep) {
      setStepIncomplete(!isValid);
    }
  }, [isValid, attemptedNextStep]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      Object.keys(dropdownRefs).forEach((key) => {
        const field = key as keyof FormValues;
        const newIsOpen = !isDropdownOpen[field];

        if (dropdownRefs[field].current && !dropdownRefs[field].current.contains(event.target as Node)) {
          setIsDropdownOpen((prev) => ({
            ...prev,
            [field]: false,
          }));

          // When closing the dropdown
          if (!newIsOpen) {
            formik.setFieldTouched(field, true);
            formik.validateField(field);
            validateAllFields();
          }
        }
      });
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRefs]);

  const toggleDropdown = (field: keyof FormValues) => {
    const newIsOpen = !isDropdownOpen[field];
    setIsDropdownOpen((prev) => ({ ...prev, [field]: newIsOpen }));

    // When closing the dropdown
    if (!newIsOpen) {
      formik.setFieldTouched(field, true);
      formik.validateField(field);
      validateAllFields();
    }
  };

  const addOption = (field: keyof FormValues, option: string) => {
    const newValue = [...(formik.values[field] as string[]), option];
    formik.setFieldValue(field, newValue);
    formik.setFieldTouched(field, true);
  };

  const removeOption = (field: keyof FormValues, option: string) => {
    const newValue = (formik.values[field] as string[]).filter((item: string) => item !== option);
    formik.setFieldValue(field, newValue);
    formik.setFieldTouched(field, true);
  };

  const validateAllFields = async () => {
    await formik.validateForm();
    setIsValid(Object.keys(formik.errors).length === 0);
  };

  return (
    <div className="container">
      <div className="row text-white">
        <div className="col-12 text-center mb-5 mt-5">
          <h2 className="fw-bold fs-2qx fs-lg-2qx mw-1000px text-uppercase m-auto text-white">
            Provide us with a few details to setup your{' '}
            <span className="fw-bolder alt-purple">investor profile</span> with the below criteria
          </h2>
          <p className="fs-5 fs-lg-2 mt-4 mt-lg-8 mw-1000px m-auto">
            Creating an account with us allows you to view your report on our system after is has
            been generated.
          </p>
        </div>
      </div>

      <div className="fv-row">
        <div
          className="card mb-5 mb-xl-10 alt-bg-gunmetal mw-650px m-auto mt-10"
          id="kt_profile_details_view"
        >
          <div className="card-body p-9">

            {investorCriteria && (
              <>
                <div className="fv-row mb-6">
                  <label className="form-label fw-bolder text-light fs-6 required">Industry</label>
                  <select
                    {...formik.getFieldProps('industry')}
                    className={clsx(
                      'form-select',
                      { 'is-invalid': formik.touched.industry && formik.errors.industry },
                      { 'is-valid': formik.touched.industry && !formik.errors.industry },
                    )}
                  >
                    <option value="">Select Industry</option>
                    {investorCriteria.industry.map((ind: any) => (
                      <option key={ind.name} value={ind.name}>{ind.name}</option>
                    ))}
                  </select>
                  {formik.touched.industry && formik.errors.industry && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.industry}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="fv-row mb-6">
                  <label className="form-label fw-bolder text-light fs-6 required">Industry sub-sector</label>
                  <select
                    {...formik.getFieldProps('subSector')}
                    className={clsx(
                      'form-select',
                      { 'is-invalid': formik.touched.subSector && formik.errors.subSector },
                      { 'is-valid': formik.touched.subSector && !formik.errors.subSector },
                    )}
                  >
                    <option value="">Select Sub-sector</option>
                    {formik.values.industry && investorCriteria.industry.find(ind => ind.name === formik.values.industry)?.sub_sector.map((sub: string) => (
                      <option key={sub} value={sub}>{sub}</option>
                    ))}
                  </select>
                  {formik.touched.subSector && formik.errors.subSector && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.subSector}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7' ref={dropdownRefs.stage}>
                  <label className='fw-bold text-light fs-6 mb-2 required'>Stage</label>
                  <div className="custom-dropdown">
                    <div
                      className="text-left form-control form-control-solid modal-textarea text-muted"
                      onClick={() => toggleDropdown('stage')}
                    >
                      {formik.values.stage.length === 0 ? (
                        'Select stages'
                      ) : (
                        <div className="d-flex flex-wrap gap-2">
                          {formik.values.stage.map((stage: string) => (
                            <div className="badge badge-primary z-3" key={stage}>
                              {stage}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation()
                                  removeOption('stage', stage)
                                }}
                                className="ms-1 cursor-pointer"
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.stage && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className="text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded"
                      >
                        {investorCriteria.stage.map((stage: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={stage}>
                            <input
                              type="checkbox"
                              id={`stage_${stage}`}
                              checked={formik.values.stage.includes(stage)}
                              onChange={() => formik.values.stage.includes(stage) ? removeOption('stage', stage) : addOption('stage', stage)}
                            />
                            <label htmlFor={`stage_${stage}`} className="ms-2 text-dark">{stage}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.stage && formik.errors.stage && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.stage}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="fv-row mb-7" ref={dropdownRefs.technologyPreferences}>
                  <label className="fw-bold text-light fs-6 mb-2 required">Technology</label>
                  <div className="custom-dropdown">
                    <div
                      className="text-left form-control form-control-solid modal-textarea text-muted"
                      onClick={() => toggleDropdown('technologyPreferences')}
                    >
                      {formik.values.technologyPreferences.length === 0 ? (
                        'Select technology preferences'
                      ) : (
                        <div className='d-flex flex-wrap gap-2'>
                          {formik.values.technologyPreferences.map((tech: string) => (
                            <div className='badge badge-primary z-3' key={tech}>
                              {tech}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeOption('technologyPreferences', tech);
                                }}
                                className='ms-1 cursor-pointer'
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.technologyPreferences && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className='text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded'
                      >
                        {investorCriteria.technology_preferences.map((tech: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={tech}>
                            <input
                              type='checkbox'
                              id={`tech_${tech}`}
                              checked={formik.values.technologyPreferences.includes(tech)}
                              onChange={() => formik.values.technologyPreferences.includes(tech) ? removeOption('technologyPreferences', tech) : addOption('technologyPreferences', tech)}
                            />
                            <label htmlFor={`tech_${tech}`} className="ms-2 text-dark">{tech}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.technologyPreferences && formik.errors.technologyPreferences && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.technologyPreferences}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7' ref={dropdownRefs.businessModel}>
                  <label className='fw-bold text-light fs-6 mb-2 required'>Business model</label>
                  <div className='custom-dropdown'>
                    <div
                      className='text-left form-control form-control-solid modal-textarea text-muted'
                      onClick={() => toggleDropdown('businessModel')}
                    >
                      {formik.values.businessModel.length === 0 ? (
                        'Select business models'
                      ) : (
                        <div className='d-flex flex-wrap gap-2'>
                          {formik.values.businessModel.map((model: string) => (
                            <div className='badge badge-primary z-3' key={model}>
                              {model}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeOption('businessModel', model);
                                }}
                                className='ms-1 cursor-pointer'
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.businessModel && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className='text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded'
                      >
                        {investorCriteria.business_model.map((model: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={model}>
                            <input
                              type='checkbox'
                              id={`model_${model}`}
                              checked={formik.values.businessModel.includes(model)}
                              onChange={() => formik.values.businessModel.includes(model) ? removeOption('businessModel', model) : addOption('businessModel', model)}
                            />
                            <label htmlFor={`model_${model}`} className="ms-2 text-dark">{model}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.businessModel && formik.errors.businessModel && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.businessModel}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7' ref={dropdownRefs.geographicFocus}>
                  <label className='fw-bold text-light fs-6 mb-2 required'>Geography</label>
                  <div className='custom-dropdown'>
                    <div
                      className='text-left form-control form-control-solid modal-textarea text-muted'
                      onClick={() => toggleDropdown('geographicFocus')}
                    >
                      {formik.values.geographicFocus.length === 0 ? (
                        'Select geographic focus'
                      ) : (
                        <div className='d-flex flex-wrap gap-2'>
                          {formik.values.geographicFocus.map((geo: string) => (
                            <div className='badge badge-primary z-3' key={geo}>
                              {geo}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeOption('geographicFocus', geo);
                                }}
                                className='ms-1 cursor-pointer'
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.geographicFocus && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className='text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded'
                      >
                        {investorCriteria.geographic_focus.map((geo: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={geo}>
                            <input
                              type='checkbox'
                              id={`geo_${geo}`}
                              checked={formik.values.geographicFocus.includes(geo)}
                              onChange={() => formik.values.geographicFocus.includes(geo) ? removeOption('geographicFocus', geo) : addOption('geographicFocus', geo)}
                            />
                            <label htmlFor={`geo_${geo}`} className="ms-2 text-dark">{geo}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.geographicFocus && formik.errors.geographicFocus && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.geographicFocus}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7' ref={dropdownRefs.revenueMargins}>
                  <label className='fw-bold text-light fs-6 mb-2 required'>Annual revenue</label>
                  <div className='custom-dropdown'>
                    <div
                      className='text-left form-control form-control-solid modal-textarea text-muted'
                      onClick={() => toggleDropdown('revenueMargins')}
                    >
                      {formik.values.revenueMargins.length === 0 ? (
                        'Select revenue margins'
                      ) : (
                        <div className='d-flex flex-wrap gap-2'>
                          {formik.values.revenueMargins.map((margin: string) => (
                            <div className='badge badge-primary z-3' key={margin}>
                              {margin}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeOption('revenueMargins', margin);
                                }}
                                className='ms-1 cursor-pointer'
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.revenueMargins && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className='text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded'
                      >
                        {investorCriteria.levels_of_revenue_margins.map((margin: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={margin}>
                            <input
                              type='checkbox'
                              id={`margin_${margin}`}
                              checked={formik.values.revenueMargins.includes(margin)}
                              onChange={() => formik.values.revenueMargins.includes(margin) ? removeOption('revenueMargins', margin) : addOption('revenueMargins', margin)}
                            />
                            <label htmlFor={`margin_${margin}`} className="ms-2 text-dark">{margin}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.revenueMargins && formik.errors.revenueMargins && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.revenueMargins}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7' ref={dropdownRefs.ageOfStartup}>
                  <label className='fw-bold text-light fs-6 mb-2 required'>Age (of the startup)</label>
                  <div className='custom-dropdown'>
                    <div
                      className='text-left form-control form-control-solid modal-textarea text-muted'
                      onClick={() => toggleDropdown('ageOfStartup')}
                    >
                      {formik.values.ageOfStartup.length === 0 ? (
                        'Select age of startup'
                      ) : (
                        <div className='d-flex flex-wrap gap-2'>
                          {formik.values.ageOfStartup.map((age: string) => (
                            <div className='badge badge-primary z-3' key={age}>
                              {age}
                              <div
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeOption('ageOfStartup', age);
                                }}
                                className='ms-1 cursor-pointer'
                              >
                                x
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {isDropdownOpen.ageOfStartup && (
                      <div
                        style={{
                          zIndex: '100',
                          width: 'calc(100% - 3em)',
                          maxHeight: '11em',
                          overflowY: 'scroll',
                        }}
                        className='text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded'
                      >
                        {investorCriteria.age_of_startup.map((age: string) => (
                          <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={age}>
                            <input
                              type='checkbox'
                              id={`age_${age}`}
                              checked={formik.values.ageOfStartup.includes(age)}
                              onChange={() => formik.values.ageOfStartup.includes(age) ? removeOption('ageOfStartup', age) : addOption('ageOfStartup', age)}
                            />
                            <label htmlFor={`age_${age}`} className="ms-2 text-dark">{age}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {formik.touched.ageOfStartup && formik.errors.ageOfStartup && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger fw-bold">
                        <span role="alert">{formik.errors.ageOfStartup}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {uploadError && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-danger fw-bold">
                  <span role="alert">{uploadError}</span>
                </div>
              </div>
            )}

            {responseError && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-danger fw-bold">
                  <span role="alert">{responseError}</span>
                </div>
              </div>
            )}

            {responseSuccess && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-success fw-bold">
                  <span role="alert">{responseSuccess}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1Investor}
