import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';

const itemClass = 'ms-1 ms-md-4';
const btnIconClass = 'fs-2';

const Navbar = () => {
  const { config } = useLayout();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    try {
      // @ts-ignore
      const user = JSON.parse(window.localStorage.getItem('user'));
      if (user) {
        setUser(user);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className='cursor-pointer symbol alt-white fw-bold'
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          {user?.username ? (
            <span>{user.username}</span>
          ) : (
            <span>User Name</span>
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
}

export {Navbar};
