/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {WizardLayoutAlt} from '../modules/auth/WizardLayoutAlt'
import {AccountReprocessWizard} from '../modules/wizards/AccountReprocessWizard'
import {HomePageLayout} from '../modules/auth/HomePageLayout'
import {HomePage} from '../modules/auth/components/HomePage'
import {AuthLayoutAlt} from '../modules/auth/AuthLayoutAlt'
import {ComingSoon} from '../modules/auth/components/ComingSoon'
import {InvestorReprocessWizard} from '../modules/wizards/InvestorReprocessWizard'
import {InvestorPage} from '../modules/auth/InvestorPage'
import {ChatbotLayout} from '../../_metronic/layout/ChatbotLayout'
import {ChatbotPage} from '../modules/pages/ChatbotPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          <Route element={<HomePageLayout />}>
            <Route path='startups/pitch_readiness' element={<HomePage />} />
          </Route>

          <Route element={<InvestorPage />}>
            <Route path='investor' element={<HomePage />} />
          </Route>

          <Route element={<ChatbotLayout/>}>
            <Route path='chatbot' element={<ChatbotPage/>}/>
          </Route>

          {currentUser ? (
            <>
              <Route index element={<Navigate to='/reports' />} />
              <Route path='/*' element={<PrivateRoutes />} />

              <Route element={<WizardLayoutAlt />}>
                <Route path='new-report' element={<AccountReprocessWizard />} />
              </Route>

              <Route element={<WizardLayoutAlt />}>
                <Route path='investor-report' element={<InvestorReprocessWizard />} />
              </Route>
            </>
          ) : (
            <>
              <Route element={<AuthLayoutAlt />}>
                <Route index element={<ComingSoon />} />
              </Route>

              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth/login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
