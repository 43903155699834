/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const HomePageLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    const body = document.getElementById('kt_app_body')
    if (root) {
      root.style.height = '100%'
    }
    if (body) {
      body.style.backgroundColor = '#F7F7F7'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
      if (body) {
        body.style.backgroundColor = '#F7F7F7'
      }
    }
  }, [])

  return (
    <>
      {/* HEADER */}
      <header className='container-fluid'>
        <nav className='navbar navbar-expand-md navbar-light mt-6 mb-4'>
          <div className='container-fluid'>
            <a className='navbar-brand' href='/'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/alt-logo-full.png')}
                className='mb-1 float-start'
                style={{width: `340px`}}
              />
            </a>
            <button
              className='navbar-toggler alt-bg-purple py-4 px-4 me-10'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarCollapse'
              aria-controls='navbarCollapse'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon alt-white'></span>
            </button>
            <div className='collapse navbar-collapse ms-sm-10' id='navbarCollapse'>
              <ul className='navbar-nav me-auto text-uppercase fw-bold' style={{fontSize: '18px'}}>
                {/* Navbar */}
              </ul>
              <form className='d-flex' role='search'>
                <a className='navbar-brand' href='/auth/login'>
                  <button
                    className='btn alt-bg-purple alt-white px-20 me-12 text-uppercase'
                    type='button'
                  >
                    Login
                  </button>
                </a>
              </form>
            </div>
          </div>
        </nav>
      </header>
      {/* HEADER */}

      <main>
        {/* HEADING */}
        <div className='container-fluid pt-2 pb-20 z-index-2 position-relative overflow-x-hidden'>
          <div
            className='min-h-900px pt-20 pb-20 text-uppercase'
            style={{
              background: `url(${toAbsoluteUrl('/media/surge/heading.svg')}) top center no-repeat`,
              backgroundSize: 'cover',
              marginRight: '-10px',
              marginLeft: '-10px',
            }}
          >
            <div className='container' style={{marginTop: '220px'}}>
              <div className='float-start mw-500px'>
                <div className='text-light fs-base pb-2 fs-3 w-100'>Alpha Release</div>

                {/* begin::Text */}
                <div className='text-light fs-base pb-2 fw-bolder fs-2qx fs-lg-3qx'>
                  Get a detailed pitch deck review.
                </div>
                {/* end::Text */}

                {/* begin::Text */}
                <div className='text-light fs-base fs-2 fw-semibold pb-10'>
                  Fast-track your funding with the ultimate startup co-pilot
                </div>
                {/* end::Text */}

                <a href={'/auth/persona'}>
                  <button
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary alt-bg-purple py-4 px-20 text-center text-uppercase fs-1'
                  >
                    Upload Pitch
                  </button>
                </a>
              </div>
            </div>

            <div>
              <img
                className='float-end position-absolute bottom-0 end-25'
                alt='Report'
                src={toAbsoluteUrl('/media/surge/triangles.svg')}
                width='200'
              />
            </div>
          </div>
        </div>
        {/* HEADING */}

        {/* CTA */}
        <div className='container-fluid z-index-2 position-relative'>
          <div className='row mt-20'>
            <div className='col-12 px-10 text-center m-auto' style={{maxWidth: `1200px`}}>
              <p className='fw-bold fs-2qx fs-lg-3qx text-uppercase'>
                You only get <span className='fw-bolder alt-purple'>one chance</span> to <br />{' '}
                impress an investor. <span className='fw-bolder alt-purple'>Make it count.</span>
              </p>
              <p className='fs-2 mb-10'>
                AltSurge is an AI-powered, high-performance co-pilot designed by seasoned investors
                and entrepreneurs who understand how difficult it can be to make a good first
                impression and get constructive feedback from investors. Let AltSurge help you to
                think through the tough questions and perfect your pitch before you submit it to an
                investor in real life.
              </p>
              <a href={'/auth/persona'}>
                <button
                  type='button'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary alt-bg-purple py-4 px-20 text-center text-uppercase fs-1'
                >
                  Get Started!
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* CTA */}

        {/* REPORT */}
        <div className='container-fluid z-index-2 position-relative overflow-x-hidden'>
          <div
            className='min-h-900px pt-5 pb-5'
            style={{
              background: `url(${toAbsoluteUrl('/media/surge/top.svg')}) top center no-repeat`,
              backgroundSize: 'cover',
              marginRight: '-10px',
              marginLeft: '-10px',
            }}
          >
            <div className='container min-h-900px' style={{marginTop: '120px'}}>
              <div className='row'>
                <div className='col-12 col-lg-7 mb-5 mb-lg-0'>
                  <h2 className='fw-bold fs-2qx fs-lg-3qx text-uppercase mt-4 mt-sm-4 mt-md-4 mt-lg-10 mt-xl-20 pt-4 pt-sm-4 pt-md-4 pt-lg-10 pt-xl-20 mw-1000px'>
                    Optimal <span className='fw-bolder alt-purple'>data-based mentorship</span> to
                    propel your startup forward
                  </h2>
                  <p className='fs-2 mt-4 mw-1000px'>
                    AltSurge is an AI-powered platform that analyzes and scores your pitch deck on
                    crucial elements important to Angel and VC investors.
                  </p>
                  <p className='fs-2 mt-4 mw-1000px'>
                    Get actionable insights in a custom pitch readiness report. <span className="fw-bold">The detailed
                    analysis covers your problem statement, market potential, timing, competitors,
                    business model, team, traction and financials, vision, the deal, and more.</span> The
                    report will unpack your pitch's strengths and weaknesses and give you an overall
                    score of your readiness to pitch to real investors.
                  </p>
                  <p className='fs-2 mt-4 mw-1000px'>
                    It’s like having access to a mentor with the collective knowledge of all Angel
                    and VC investors in one place.
                  </p>
                </div>
                <div className='col-12 col-lg-5 text-center'>
                  <img
                    className='img-fluid'
                    alt='Report'
                    src={toAbsoluteUrl('/media/surge/report.svg')}
                  />
                  <div className='w-100 text-center mt-4'>
                    <a href='/media/Pitch_Readiness_report_sample.pdf' className='fs-5 fw-bold alt-purple text-uppercase'>
                      View Sample Report
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* REPORT */}

        {/* PERSONAS */}
        <div className='container mt-xs-0 mt-sm-0 mt-md-0 move-up'>
          <div className='row'>
            <div className='col-12 text-center mb-5'>
              <h2 className='fw-bold fs-2qx fs-lg-3qx mw-900px text-uppercase m-auto'>
                <span className='fw-bolder alt-purple'>Customized feedback</span> in the style of a
                famous investor
              </h2>
              <p className='fs-5 fs-lg-2 mt-4 mt-lg-10 mw-900px m-auto'>
                The AltSurge AI can give you feedback in the style of your favorite investor. Choose
                the investor voice you resonate with most for custom feedback on your pitch:
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid rounded-circle'
                  alt='Persona'
                  src={toAbsoluteUrl(
                    'https://project-io.metalogix.solutions/uploaded_docs/avatars/ec5c1c90-82cd-456e-a3d5-06a416e901d2_160.webp'
                  )}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                AFRICAN ECOSYSTEM BUILDER
              </h2>
              <p className='fs-6 fs-lg-4'>
                Inclusive, collaborative, empathetic, sustainable impact-driven.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid rounded-circle'
                  alt='Persona'
                  src={toAbsoluteUrl(
                    'https://project-io.metalogix.solutions/uploaded_docs/avatars/de683572-4ac5-47d8-ad5d-5f7d7a1ae514_160.webp'
                  )}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                CONTRARIAN BILLIONAIRE FOUNDER
              </h2>
              <p className='fs-6 fs-lg-4'>
                10x-seeking, monopoly-minded, brutally honest, metaphorical, seeks evangelical founders.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid rounded-circle'
                  alt='Persona'
                  src={toAbsoluteUrl(
                    'https://project-io.metalogix.solutions/uploaded_docs/avatars/3f67e332-2ab6-4ed5-ab67-c8b989aeab37_160.webp'
                  )}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                VISIONARY JAPANESE BILLIONAIRE
              </h2>
              <p className='fs-6 fs-lg-4'>
                Analytical, tech-focused, with an emphasis on innovation and impact.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid rounded-circle'
                  alt='Persona'
                  src={toAbsoluteUrl(
                    'https://project-io.metalogix.solutions/uploaded_docs/avatars/0b15e550-0b59-4504-b15d-2eefe3777c0e_160.webp'
                  )}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                PHILANTROPIC TECH VETERAN
              </h2>
              <p className='fs-6 fs-lg-4'>
                Equity-focused, data-driven, catalytic, humble, empowering.
              </p>
            </div>
          </div>
        </div>
        {/* PERSONAS */}

        {/* UPLOAD */}
        <div
          className='container-fluid'
          style={{
            background: `url(${toAbsoluteUrl('/media/surge/stripes.svg')}) top left no-repeat`,
            backgroundSize: 'cover',
            paddingTop: '450px',
            marginTop: '-320px',
            minHeight: '1420px',
          }}
        >
          <div className='row'>
            <div className='col-12 col-md-6 text-center'>
              <img
                className='float-md-end m-auto img-fluid'
                alt='Report'
                src={toAbsoluteUrl('/media/surge/car.svg')}
              />
            </div>
            <div className="col-12 col-md-6 px-4 px-md-5 mt-4 mt-md-6 mt-lg-10 mt-xl-20 pt-4 pt-md-6 pt-lg-14 pt-xl-20">
              <h1 className="fw-bold text-uppercase fs-2qx fs-lg-3qx mw-600px pb-2">
                Here’s how to step into the fast lane
              </h1>
              <ol className="fs-5 fs-md-3">
                <li>Choose your pitch feedback style</li>
                <li>Upload your pitch deck</li>
                <li>Create an account and pay $20</li>
                <li>Get your detailed report within 24 hours</li>
                <li>Ask follow-up questions directly to our AI to refine your pitch further</li>
              </ol>
              <a href={'/auth/persona'}>
                <button
                  type="button"
                  id="kt_sign_up_submit"
                  className="btn btn-lg btn-primary alt-bg-purple py-3 py-md-4 px-4 px-md-20 text-center text-uppercase fs-6 fs-md-1"
                >
                  Upload Pitch ($20)
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* UPLOAD */}

        {/* FEEDBACK */}
        <div className="container py-5 move-up-feedback">
          <div className="row mt-5">
            <div className='col-12 px-3 px-md-5 m-auto' style={{maxWidth: '1100px'}}>
              <p className='fw-bold fs-2qx fs-lg-3qx text-uppercase text-center mb-5'>
                Feedback from founders
              </p>

              <div
                id='myCarousel'
                className='carousel slide mw-1000px m-auto alt-bg-dark rounded-4 text-light'
                data-bs-ride='carousel'
              >
                {/* <div className='carousel-indicators'>
                  <button
                    type='button'
                    data-bs-target='#myCarousel'
                    data-bs-slide-to='0'
                    className='active'
                    aria-current='true'
                    aria-label='Slide 1'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#myCarousel'
                    data-bs-slide-to='1'
                    aria-label='Slide 2'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#myCarousel'
                    data-bs-slide-to='2'
                    aria-label='Slide 3'
                  ></button>
                </div> */}
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <div className='container'>
                      <div className='carousel-caption text-start'>
                        <p className='fs-5 fs-sm-5 fs-lg-3 fs-xl-3 pt-0 pt-md-6'>
                          “The Pitch Readiness report was very helpful at identifying the most important
                          issues in our pitch that needed attention and were preventing investors from
                          moving ahead. Unfortunately, investors almost never tell you what the
                          problems are with your pitch, so the detailed feedback was great!”
                        </p>
                        <p className='fs-5 fs-sm-5 fs-lg-3 fs-xl-3'>Vinny Lingham, CEO & Co-founder, Rumi.ai</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  className='carousel-control-prev'
                  type='button'
                  data-bs-target='#myCarousel'
                  data-bs-slide='prev'
                >
                  <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                  <span className='visually-hidden'>Previous</span>
                </button>
                <button
                  className='carousel-control-next'
                  type='button'
                  data-bs-target='#myCarousel'
                  data-bs-slide='next'
                >
                  <span className='carousel-control-next-icon' aria-hidden='true'></span>
                  <span className='visually-hidden'>Next</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* FEEDBACK */}

        {/* FAQ */}
        <div className='container pb-5 z-index-3 position-relative mt-20'>
          <div className='row mt-5'>
            <div className='col-12 px-3 px-md-5 m-auto' style={{maxWidth: '1100px'}}>
              <p className='fw-bold fs-2qx fs-lg-3qx text-uppercase text-center mb-5'>
                Frequently Asked Questions
              </p>
              <div className='card mb-4'>
                <div className='card-body pt-4 pb-0'>
                  <div className='row'>
                    <div className='col-12 col-md-6 mb-4'>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple pb-2'>Why is there a fee for the report?</h4>
                        <p className='fs-6'>
                          AI models are expensive! Your fee covers an in-depth, detailed analysis of
                          your specific pitch across the most important dimensions, not just general
                          feedback. We invest in delivering value that impacts your funding journey.
                        </p>
                      </div>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple'>Is my data safe?</h4>
                        <p className='fs-6'>
                          Yes, startup data is stored in a decentralized, zero-knowledge database
                          built on blockchain technology with strong access controls enabled by
                          smart contracts.
                        </p>
                      </div>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple'>
                          Do you share my pitch with third parties?
                        </h4>
                        <p className='fs-6'>
                          If you score above a certain threshold, we’ll ask your permission to share
                          your pitch with investors who may be a good fit for your startup. If you
                          don’t give us permission, we won’t send your pitch to anyone.
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 mb-4'>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple'>How many times can I upload my deck?</h4>
                        <p className='fs-6'>
                          Upload as often as you need for just $10 each to refine your pitch
                          continually.
                        </p>
                      </div>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple'>What document formats do you accept?</h4>
                        <p className='fs-6'>Currently, we accept pitch decks in PDF format.</p>
                      </div>
                      <div className='mb-4'>
                        <h4 className='fs-5 alt-purple'>Does it work for all startup stages?</h4>
                        <p className='fs-6'>
                          Our alpha release is optimized for early-stage startups from Pre-seed to
                          Series A.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}

        {/* FOOTING */}
        <div
          className='container-fluid pb-20 z-index-2 position-relative'
          style={{
            background: `url(${toAbsoluteUrl('/media/surge/footing.svg')}) top center no-repeat`,
            backgroundSize: 'cover',
            minHeight: '1200px',
            marginTop: '-250px',
          }}
        >
          <div className='container'>
            <div className='row pt-10 pb-20 mt-20 pt-20'>
              <div style={{marginTop: '420px'}}>
                <div className='float-start mw-600px'>
                  {/* begin::Text */}
                  <div className='text-light fs-2 pb-2 fw-bold fs-2qx fs-lg-3qx text-uppercase'>
                    AI precision meets startup ambition.
                  </div>
                  {/* end::Text */}

                  {/* begin::Text */}
                  <div className='text-light fs-2 fw-semibold pb-10'>
                    AltSurge is an AI-powered, high-performance co-pilot that bridges the gap
                    between startup founders and early-stage investors. We are in our Alpha release
                    phase for startups, and we have big plans for the future.
                  </div>
                  {/* end::Text */}

                  {/*<a href={'/auth/persona'}>
                    <button
                      type="button"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary alt-bg-purple py-4 px-20 text-center text-uppercase fs-1"
                    >
                      Learn More
                    </button>
                  </a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTING */}

        {/* FOOTER */}
        <footer
          className='container-fluid py-5 alt-bg-dark position-relative'
          style={{marginTop: '-250px'}}
        >
          <div className='container text-dark' style={{marginTop: '200px'}}>
            <div className='row'>
              <div className='col-12 col-md-6 text-center text-md-start'>
                <div className='mw-800px m-auto'>
                  <div className='float-md-end float-lg-end float-xl-end pe-3 mt-1'>
                    <Link to='/'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/surge/logo-footer.png')}
                        className='img-fluid'
                        style={{maxWidth: '380px', height: 'auto'}}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 text-center text-md-start'>
                <div className='pt-4 fs-4'>
                  <p className='text-light fw-semibold mt-8 mb-2'>
                    For more information, please email us at support at{' '}
                    <a href='https://altsurge.ai' target='_blank' rel="noreferrer">
                      altsurge.ai
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 text-center'>
            <div className='col-12'>
              <p className='text-light fs-7'>
                © {new Date().getFullYear().toString()} ALTSURGE NETHERLANDS B.V. ALL RIGHTS
                RESERVED.
              </p>
            </div>
          </div>
        </footer>
        {/* FOOTER */}
      </main>
    </>
  )
}

export {HomePageLayout}
