/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div className="d-flex w-100" style={{backgroundColor: `#0E252F`}}>
        {/* begin::Logo */}
        <div className={'w-25 fs-7qx'}>
          <Link to="/">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/alt-logo-dark.png')} className="mt-4 float-start" style={{width: `180px`}} />
          </Link>
        </div>
        {/* end::Logo */}
      </div>

      <div className="d-flex flex-column flex-lg-row flex-column-fluid"
         style={{
           backgroundRepeat: `no-repeat`,
           backgroundSize: `cover`,
           backgroundPosition: `top center`,
           backgroundColor: `#0E252F`,
         }}
      >
        {/* begin::Body */}
        <div className="d-flex flex-column flex-lg-row-fluid w-sm-100 w-lg-50 p-10 order-2 order-lg-1">
          {/* begin::Form */}
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/* begin::Wrapper */}
            <div className="w-sm-500px w-lg-500px p-10">

              {/* begin::Outlet */}
              <Outlet />
              {/* end::Outlet */}

            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {AuthLayout}
