/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {resetPassword} from '../core/_requests'
import { useNavigate } from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

const initialValues = {
  password_confirmation: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [currentSite, setCurrentSite] = useState('io')
  const {saveAuth, setCurrentUser} = useAuth()
  const [token, setToken]: any = useState('')
  const {search} = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const token = query.get('token')
    setToken(token)
  }, [])

  useEffect(() => {
    localStorage.setItem('currentSite', 'io')
    const params = new URLSearchParams(search)
    const site = params.get('site')
    if (site && (site === 'io' || site === 'alt')) {
      localStorage.setItem('currentSite', site)
      setCurrentSite(site)
    }
  }, [search])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const auth: any = await resetPassword(token, values.password)

        if (auth.data.status !== 'fail') {
          navigate('/auth/login')
        } else {
          setStatus('Your token has expired')
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('An unexpected error has occured')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-light fw-bolder mb-3'>Reset Password</h1>
        <div className='text-white fw-semibold fs-6'>Reset your current password</div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className="mb-1">
          <label className='form-label fw-bolder text-light fs-6 mb-0'>New Password</label>
          <div className="position-relative mb-3">
            <input
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container text-white fw-bold'>
                <div className='fv-help-block'>
                  <span className='text-white fw-bold' role='alert'>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-white">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Confirm New Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control',
            {
              'is-invalid':
                formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid':
                formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container text-white fw-bold'>
            <div className='fv-help-block'>
              <span className='text-white fw-bold' role='alert'>
                {formik.errors.password_confirmation}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
