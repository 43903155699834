/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const WizardLayoutAlt = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    const body = document.getElementById('kt_app_body')
    if (root) {
      root.style.height = '100%'
    }
    if (body) {
      body.style.backgroundColor = '#0E252F'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
      if (body) {
        body.style.backgroundColor = '#0E252F'
      }
    }
  }, [])

  return (
    <>
      <div className='row w-100 h-100 alt-bg-gunmetal'>
        {/* begin::Body */}
        <div className='col-12 pb-10'>
          {/* begin::Form */}
          <div className='pb-4'>
            <Outlet />
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}
      </div>

      <div className='d-flex w-100 alt-bg-night position-fixed bottom-0 left-0 py-4'>
        <div className='text-dark alt-white order-2 order-md-1 fs-7'>
          <span className='ps-4 alt-white fw-semibold me-1'>
            {new Date().getFullYear().toString()} &copy;
          </span>
          Copyright AltSurge Netherlands - All rights reserved
        </div>
      </div>
    </>
  )
}

export {WizardLayoutAlt}
