/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import IPConfig from '../../../app/store/IPConfig'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import {toAbsoluteUrl, defaultMessages, MessageModel} from '../../helpers'

type Props = {
  personaName?: string
  personaAvatar?: string
  setPersonaName?: any
  setPersonaAvatar?: any
  isDrawer?: boolean
  token: string
}

const bufferMessages = defaultMessages

const Chatbot: FC<Props> = ({
  isDrawer = false,
  personaName = 'Chatbot',
  personaAvatar = toAbsoluteUrl(`/media/surge/avatar.jpg`),
  setPersonaName,
  setPersonaAvatar,
  token,
}) => {
  const [userId, setUserId] = useState<any>(null)
  const [userName, setUserName] = useState<string>('You')
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages]: any = useState<MessageModel[]>([])
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const [chatCompleted, setChatCompleted] = useState(false)
  const [textareaHeight, setTextareaHeight] = useState(40);
  const [hasContent, setHasContent] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const scrollRef: any = useRef(null)

  const [selectedReportName, setSelectedReportName] = useState(
    () => localStorage.getItem('selectedReportName') || 'Pitch Readiness Report'
  )
  const [selectedJobId, setSelectedJobId]: any = useState(
    () => localStorage.getItem('selectedJobId') || null
  )
  const [selectedPersonaName, setSelectedPersonaName] = useState(
    () => localStorage.getItem('selectedPersonaName') || 'Chatbot'
  )
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState(
    () => localStorage.getItem('selectedPersonaAvatar') || ''
  )

  const apiUri = IPConfig()
  const apiUriMetis = IPConfig('metis')
  const publicUrl = IPConfig('public')

  useEffect(() => {
    // @ts-ignore
    setUserId(window.localStorage.getItem('user_id'))

    setMessages([]);
    setMessage('');

    setSelectedJobId(null);
  }, [])

  useEffect(() => {
    // send initial message
    if (token) {
      sendInitialize();
    }
  }, [token])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedReportName') {
        setSelectedReportName(event.newValue || 'Pitch Readiness Report')
      } else if (event.key === 'selectedJobId') {
        setSelectedJobId(event.newValue || null)
      } else if (event.key === 'selectedPersonaName') {
        setSelectedPersonaName(event.newValue || 'Chatbot')
      } else if (event.key === 'selectedPersonaAvatar') {
        setSelectedPersonaAvatar(event.newValue || '')
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      const lastMessageIndex = messages.length - 1;
      const lastMessage = document.getElementById(`message-${lastMessageIndex}`);
      if (lastMessage && (messages[lastMessageIndex].sender === 'bot' || messages[lastMessageIndex].sender === 'user')) {
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [messages])

  useEffect(() => {
    if (!hasContent) {
      setTextareaHeight(40);
    }
  }, [hasContent])

  useEffect(() => {
    if (chatCompleted) {
      const terminationMessage = {
        text: "Chat has been terminated by the agent.",
        sender: 'bot',
      }
      setMessages((prevMessages) => [...prevMessages, terminationMessage])
    }
  }, [chatCompleted])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && event.target instanceof Element) {
        if (!event.target.closest('.dropdown-menu') && !event.target.closest('.btn-active-light-primary')) {
          setDropdownOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (chatCompleted) {
      return;
    }

    const formData = new FormData()
    formData.append('token', token)

    if (selectedJobId) {
      formData.append('job_id', selectedJobId)
    }

    if (selectedFile && message.trim() === '') {
      formData.append('message', selectedFile.name)
      formData.append('user_message', selectedFile.name)
    } else {
      formData.append('message', message)
      formData.append('user_message', message)
    }

    if (selectedFile) {
      formData.append('file', selectedFile)
    }

    if (message.trim() !== '' || selectedFile) {

      let userMessage = {
        text: message,
        sender: 'user',
      };

      if (selectedFile && message.trim() === '') {
        userMessage = {
          text: selectedFile.name,
          sender: 'user',
        }
      }

      setIsLoadingMessage(true)
      setMessages((prevMessages) => [...prevMessages, userMessage])

      const apiUrl = `${apiUri}/chat`
      const apiUrlMetis = `${apiUriMetis}/chatbot-chat`

      fetch(apiUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          const botMessage = {
            text: response.data,
            avatar: response.avatar,
            agent_name: response.agent_name,
            knowledge_base_used: 'Knowledge Base', // response.knowledge_base_used,
            sender: 'bot',
          }

          if (response.agent_name) {
            setPersonaName(response.agent_name);
            setSelectedPersonaName(response.agent_name);
          }

          if (response.avatar) {
            setPersonaAvatar(response.avatar);
            setSelectedPersonaAvatar(response.avatar);
          }

          if (response.user_name) {
            setUserName(response.user_name);
          }

          setMessages((prevMessages) => [...prevMessages, botMessage])
          setIsLoadingMessage(false)
          setChatCompleted(response.complete)
          localStorage.setItem('messages', JSON.stringify(messages))
        })
        .catch((error) => {
          console.error('failed:', error.message)
          setIsLoadingMessage(false)
          setChatCompleted(false)
          const botMessage = {
            text: error.message,
            sender: 'bot',
          }
          setMessages((prevMessages) => [...prevMessages, botMessage])
        })

      // Clear chat states
      setMessage('');
      setHasContent(false);
      setTextareaHeight(40);
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }

  const sendInitialize = () => {
    setIsLoadingMessage(true)

    const apiUrl = `${apiUri}/chat`
    const formData = new FormData()
    formData.append('token', token)

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        try {
          const botMessage = {
            text: response.data,
            avatar: response.avatar,
            agent_name: response.agent_name,
            knowledge_base_used: 'Knowledge Base', // response.knowledge_base_used,
            sender: 'bot',
          }

          if (response.agent_name) {
            setPersonaName(response.agent_name);
            setSelectedPersonaName(response.agent_name);
          }

          if (response.avatar) {
            setPersonaAvatar(response.avatar);
            setSelectedPersonaAvatar(response.avatar);
          }

          if (response.user_name) {
            setUserName(response.user_name);
          }

          if (response.can_upload) {
            setAllowUpload(response.can_upload);
          }

          setMessages((prevMessages) => [...prevMessages, botMessage])
          setIsLoadingMessage(false)
          setChatCompleted(response.complete)
          localStorage.setItem('messages', JSON.stringify(messages))
        } catch(error) {
          console.error('failed:', error)
          setIsLoadingMessage(false)
          setChatCompleted(false)
          const botMessage = {
            text: "Something went wrong",
            sender: 'bot',
          }
          setMessages((prevMessages) => [...prevMessages, botMessage])
        }
      })
      .catch((error) => {
        console.error('failed:', error.message)
        setIsLoadingMessage(false)
        const botMessage = {
          text: error.message,
          sender: 'bot',
        }
        setMessages((prevMessages) => [...prevMessages, botMessage])
      })
  }

  const handleTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleFormSubmit(e as any)
    } else if (e.key === 'Enter') {
      adjustTextareaHeight(e.target as HTMLTextAreaElement);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setMessage(newValue);
    setHasContent(newValue.trim().length > 0);

    // Only adjust height if the content exceeds one line
    if (e.target.scrollHeight > e.target.clientHeight) {
      adjustTextareaHeight(e);
    }
  };

  const adjustTextareaHeight = (eventOrElement: React.ChangeEvent<HTMLTextAreaElement> | HTMLTextAreaElement) => {
    const element = 'target' in eventOrElement ? eventOrElement.target : eventOrElement;
    const newHeight = Math.min(element.scrollHeight, 120);
    setTextareaHeight(newHeight);
  };

  const handleLike = (index) => {
    console.log(`Liked message ${index}`);
    // Implement like functionality
  };

  const handleDislike = (index) => {
    console.log(`Disliked message ${index}`);
    // Implement dislike functionality
  };

  const handleReadAloud = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(speech);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    });
  };

  const handleSpeechRecognition = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!('webkitSpeechRecognition' in window)) {
      alert("Speech recognition is not supported in your browser. Please try Chrome.");
      return;
    }

    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      setIsRecording(true);
    };

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      setMessage(prevMessage => {
        const newMessage = (prevMessage + ' ' + transcript).trim();
        setHasContent(newMessage.length > 0);

        // Use setTimeout to ensure the DOM has updated before adjusting height
        setTimeout(() => {
          const textarea = document.querySelector('textarea[data-kt-element="input"]') as HTMLTextAreaElement;
          if (textarea) {
            adjustTextareaHeight(textarea);
          }
        }, 0);

        return newMessage;
      });
      console.log("Transcript:", transcript);
    };

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error', event.error);
      setIsRecording(false);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };

    recognition.start();
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type === 'text/plain' || file.name.endsWith('.md')) {
        setSelectedFile(file);
        setDropdownOpen(false);
      } else {
        alert('Please upload only .txt or .md files.');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  const triggerFileInput = (e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className='card-chat pb-0 mb-0' id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}>
      <div className="d-flex flex-column h-100">
        <div
          className="flex-grow-1 overflow-auto"
          style={{maxHeight: `calc(100vh - ${120 + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`, minHeight: `calc(100vh - ${120 + (textareaHeight > 40 ? textareaHeight - 8 : textareaHeight)}px)`}}
          ref={scrollRef}
        >
          {messages.map((message, index) => {
            const state = message.sender === 'bot' ? 'info' : 'primary'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
              message.sender === 'bot' ? 'start' : 'end'
            } mb-10`
            return (
              <div
                key={`message${index}`}
                id={`message-${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items chat-wrapper',
                    `align-items-${message.sender === 'bot' ? 'start' : 'end'}`,
                  )}
                >
                  <div className={clsx(
                    'd-flex align-items-center',
                    `${message.sender === 'bot' ? 'mb-0' : 'mb-2'}`,
                  )}>
                    {message.sender === 'bot' ? (
                      <>
                        <div
                          className="symbol symbol-35px symbol-circle"
                        >
                          <img
                            alt={personaName}
                            src={
                              personaAvatar
                                ? `${publicUrl}/${personaAvatar}`
                                : toAbsoluteUrl(`/media/surge/avatar.jpg`)
                            }
                          />
                        </div>
                        <div className="ms-3">
                          <a href="#" className="fs-5 fw-bolder alt-white text-hover-primary me-1">
                            {personaName ? personaName : 'Chatbot'}
                          </a>
                          <span className="text-muted fs-7 mb-1">{message.time}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="me-3">
                          <span className="text-muted fs-7 mb-1">{message.time}</span>
                          <a href="#" className="fs-5 fw-bolder alt-white text-hover-primary ms-1">
                            {userName}
                          </a>
                        </div>
                        <div
                          className="symbol symbol-35px symbol-circle "
                        >
                          <img alt="Pic" src={toAbsoluteUrl(`/media/surge/avatar.jpg`)} />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'px-5 pb-0 font-size-all markdown chat-message position-relative',
                      `${message.sender === 'bot' ? 'bg-transparent ps-14 pt-0' : 'pt-3'}`,
                    )}
                    style={{marginTop: `${message.sender === 'bot' ? '-5px' : '0px'}`}}
                    data-kt-element="message-text"
                  >
                    <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>

                    {message.sender === 'bot' && (
                      <div className="message-actions">
                        <button className="action-icon" title="Like" onClick={() => handleLike(index)}>
                          <i className="bi bi-hand-thumbs-up-fill fs-3"></i>
                        </button>
                        <button className="action-icon" title="Dislike" onClick={() => handleDislike(index)}>
                          <i className="bi bi-hand-thumbs-down-fill fs-3"></i>
                        </button>
                        <button className="action-icon" title="Read Aloud" onClick={() => handleReadAloud(message.text)}>
                          <i className="bi bi-volume-up-fill fs-3"></i>
                        </button>
                        <button className="action-icon" title="Copy" onClick={() => handleCopy(message.text)}>
                          <i className="bi bi-clipboard-fill fs-3"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
          {isLoadingMessage && (
            <>
              <div className="mt-10 ms-10 loader"></div>
            </>
          )}
        </div>

        <div className="mt-auto">

          {selectedFile && (
            <div className="text-muted fs-7 position-absolute h-25px z-0" style={{marginTop: '-20px'}}>{selectedFile.name}</div>
          )}

          <div className="row p-0 pt-4 mb-0 border-0" id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}>

            <div className="col-12 clear clearfix">
              <form>
                <div className="d-flex flex-stack mx-2" style={{marginBottom: '-42px'}}>
                  <div className="d-flex align-items-center me-2 position-relative">
                    <button
                      className="btn btn-sm btn-icon me-1 rounded-circle chat-icon"
                      type="button"
                      data-bs-toggle="tooltip"
                      title="Add attachment"
                      disabled={chatCompleted}
                      style={{marginTop: '2px'}}
                      onClick={toggleDropdown}
                    >
                      <i className="bi bi-plus fs-2 alt-white"></i>
                    </button>
                    {dropdownOpen && (
                      <div className="dropdown-menu show dropdown-menu-chat">
                        {allowUpload && (
                          <button className="dropdown-item px-4" onClick={(e) => triggerFileInput(e)} type="button">
                            <i className="bi bi-paperclip fs-2 alt-white pe-3"></i>
                            Upload File
                          </button>
                        )}
                        <button className="dropdown-item px-4" onClick={(e) => handleSpeechRecognition(e)} type="button">
                          <i className="bi bi-mic-fill fs-2 alt-white pe-3"></i>
                          Record Audio
                        </button>
                      </div>
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{display: 'none'}}
                      onChange={handleFileSelect}
                      accept=".txt,.md"
                    />
                  </div>
                  {/*<button
                    className="btn btn-sm btn-icon btn-active-light-primary me-13 rounded-circle"
                    type="button"
                    data-bs-toggle="tooltip"
                    title={isRecording ? 'Stop Recording' : 'Record Voice'}
                    disabled={chatCompleted}
                    onClick={handleSpeechRecognition}
                  >
                    <i className={`bi ${isRecording ? 'bi-stop-fill' : 'bi-mic-fill'} fs-2 alt-white`}></i>
                  </button>*/}
                </div>
                <div className="">
                  <textarea
                    disabled={isLoadingMessage || chatCompleted}
                    className={`${chatCompleted ? 'chat-completed-input' : ''} chat-input form-control form-control-flush float-start px-16 rounded-xl`}
                    rows={1}
                    data-kt-element="input"
                    placeholder={isLoadingMessage ? 'Please wait...' : (chatCompleted ? 'Chat completed...' : 'Send a message')}
                    value={message}
                    onChange={handleInputChange}
                    onKeyDown={handleTextareaKeyDown}
                    style={{
                      height: `${textareaHeight}px`,
                      minHeight: '40px',
                      maxHeight: '120px',
                      width: 'calc(100% - 40px)',
                      overflow: 'hidden',
                    }}
                  ></textarea>
                  <button
                    className="btn btn-sm btn-icon ms-2 rounded-circle chat-icon"
                    type="button"
                    data-bs-toggle="tooltip"
                    title={hasContent || selectedFile ? 'Send Message' : 'Record Voice'}
                    style={{width: '32px', height: '32px', marginTop: '8px', paddingTop: '2px'}}
                    onClick={hasContent || selectedFile ? handleFormSubmit : handleSpeechRecognition}
                    disabled={chatCompleted || isRecording}
                  >
                    <i className={`bi ${hasContent || selectedFile ? 'bi-arrow-bar-up' : `${isRecording ? 'bi-headset' : 'bi-headphones'}`} fs-2 alt-white`}></i>
                  </button>
                </div>
              </form>
            </div>

            <div className="col-12 clear clearfix">
              <div className="chat-footer-text my-8 text-xs text-gray-500 text-center fs-7">
                <span className="chat-footer-text ps-4 fw-semibold me-1">
                  {new Date().getFullYear().toString()} &copy;
                </span>
                <a
                  href="https://altsurge.ai/"
                  target="_blank"
                  className="alt-white text-hover-primary"
                >
                  Copyright AltSurge Netherlands - All rights reserved
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export {Chatbot}
