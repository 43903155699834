/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'

const Footer = ({ isChatbotPage = false }) => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <>
      <div className={`${isChatbotPage ? 'chat-footer-text' : 'text-dark'} order-2 order-md-1 fs-7`}>
        <span className={`${isChatbotPage ? 'chat-footer-text' : 'text-muted'} ps-4 fw-semibold me-1`}>
          {new Date().getFullYear().toString()} &copy;
        </span>
        <a
          href='https://altsurge.ai/'
          target='_blank'
          className='alt-white text-hover-primary'
        >
          Copyright AltSurge Netherlands - All rights reserved
        </a>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
