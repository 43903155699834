import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AccountSetup} from './components/AccountSetup'
import {ResetPassword} from './components/ResetPassword'
import {AuthLayout} from './AuthLayout'
import {WizardLayoutAlt} from './WizardLayoutAlt'
import {AccountWizard} from '../wizards/AccountWizard'
import {TermsLayoutAlt} from './TermsLayoutAlt'
import {TermsConditions} from './components/TermsConditions'
import {PrivacyPolicy} from './components/PrivacyPolicy'
import {InvestorWizard} from '../wizards/InvestorWizard'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='account-setup' element={<AccountSetup />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
    </Route>
    <Route element={<TermsLayoutAlt />}>
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      <Route path='terms-and-conditions' element={<TermsConditions />} />
    </Route>
    <Route element={<WizardLayoutAlt />}>
      <Route path='persona' element={<AccountWizard />} />
      <Route path='investor' element={<InvestorWizard />} />
    </Route>
  </Routes>
)

export {AuthPage}
