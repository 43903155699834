/* eslint-disable react/jsx-no-target-blank */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import {useEffect, useState} from 'react'

export function TermsConditions() {
  const rawHTMLTerms = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:"Arial Narrow";
	panose-1:2 11 6 6 2 2 2 3 2 4;}
@font-face
	{font-family:"Arial Bold";
	panose-1:2 11 7 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	text-align:justify;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA1FirstLevelHeading, li.DA1FirstLevelHeading, div.DA1FirstLevelHeading
	{mso-style-name:"DA 1 First Level Heading";
	mso-style-link:"DA 1 First Level Heading Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:35.45pt;
	text-align:justify;
	text-indent:-35.45pt;
	line-height:150%;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial Bold";
	text-transform:uppercase;
	font-weight:bold;}
p.DA2SecondLevelNumbering, li.DA2SecondLevelNumbering, div.DA2SecondLevelNumbering
	{mso-style-name:"DA 2 Second Level Numbering";
	mso-style-link:"DA 2 Second Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:78.0pt;
	text-align:justify;
	text-indent:-42.55pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA3ThirdLevelNumbering, li.DA3ThirdLevelNumbering, div.DA3ThirdLevelNumbering
	{mso-style-name:"DA 3 Third Level Numbering";
	mso-style-link:"DA 3 Third Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:127.6pt;
	text-align:justify;
	text-indent:-49.65pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA4FourthLevelNumbering, li.DA4FourthLevelNumbering, div.DA4FourthLevelNumbering
	{mso-style-name:"DA 4 Fourth Level Numbering";
	mso-style-link:"DA 4 Fourth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:177.2pt;
	text-align:justify;
	text-indent:-49.6pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA5FifthLevelNumbering, li.DA5FifthLevelNumbering, div.DA5FifthLevelNumbering
	{mso-style-name:"DA 5 Fifth Level Numbering";
	mso-style-link:"DA 5 Fifth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:233.9pt;
	text-align:justify;
	text-indent:-56.7pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA6SixthLevelNumbering, li.DA6SixthLevelNumbering, div.DA6SixthLevelNumbering
	{mso-style-name:"DA 6 Sixth Level Numbering";
	mso-style-link:"DA 6 Sixth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:297.7pt;
	text-align:justify;
	text-indent:-63.8pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA7SeventhLevelNumbering, li.DA7SeventhLevelNumbering, div.DA7SeventhLevelNumbering
	{mso-style-name:"DA 7 Seventh Level Numbering";
	mso-style-link:"DA 7 Seventh Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:311.85pt;
	text-align:justify;
	text-indent:-70.8pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA8EighthLevelNumbering, li.DA8EighthLevelNumbering, div.DA8EighthLevelNumbering
	{mso-style-name:"DA 8 Eighth Level Numbering";
	mso-style-link:"DA 8 Eighth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:318.95pt;
	text-align:justify;
	text-indent:-77.95pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA9NinthLevelNumbering, li.DA9NinthLevelNumbering, div.DA9NinthLevelNumbering
	{mso-style-name:"DA 9 Ninth Level Numbering";
	mso-style-link:"DA 9 Ninth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:326.05pt;
	text-align:justify;
	text-indent:-85.05pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
.MsoChpDefault
	{font-size:10.0pt;
	font-family:"Arial",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	text-align:justify;
	line-height:150%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<div style='border:none;border-bottom:solid black 1.0pt;padding:31.0pt 31.0pt 1.0pt 31.0pt;
margin-left:4.5pt;margin-right:0in'>

<p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;page-break-after:
avoid;border:none;padding:0in'><b><span lang=EN-ZA style='font-size:14.0pt;
line-height:150%;font-variant:small-caps;color:black'>TERMS AND CONDITIONS ALTSURGE
PLATFORM</span></b></p>

<p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;page-break-after:
avoid;border:none;padding:0in'><b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;font-variant:small-caps;color:black'>Last updated: april 2024</span></b></p>

</div>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>introduction</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>AltSurge </span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>Netherlands<span style='color:black'> B.V. referred to herein
as “AltSurge”, makes a service available to </span>early-stage<span
style='color:black'> startup legal entities (“startups”) in terms whereof they
provide information to the startup that may assist them in the journey to becom</span>ing<span
style='color:black'> </span>investor-ready<span style='color:black'>. The
service (“AltSurge service” or “service”) entails that a startup can register
as a user (“registered user” or “you”), and submit a pitch desk through the
platform available on Altsurge’s website (“platform”) and receive a report
which expresses an opinion on different data points as presented (or omitted)
in the pitch desk (“report”), which will include recommendations that may
possibly assist the startup </span>in becoming<span style='color:black'> more
investible. Both the individual acting on behalf of the startup and the startup
itself will be regarded </span>as <span style='color:black'>users for purposes
of these terms and the services. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>The report </span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>is<span style='color:black'> compiled through an automated
process of </span>analyzing<span style='color:black'> the input data against
data of other startups using the platform, investor preferences as available in
the public domain or through investor interaction with the platform, internal
platform rules, and artificial intelligence.</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>AltSurge</span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>,<span style='color:black'> therefore does not provide any
form of professional or other advice, does not provide financial or investment
products or services and does not warrant that the information presented by the
report will be suitable for the user. The report is merely a compilation of
different data points as it may apply to the user.   </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>These terms (“terms”) regulate the use of the service and
platform. Reference to <i>service</i> includes the <i>platform</i>. All clauses
in <b>BOLD</b> are provisions </span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>that<span style='color:black'> limit the risk or liability of
AltSurge and constitute an assumption of risk or liability by you, impose an
obligation on you to indemnify AltSurge or is an </span>acknowledgment<span
style='color:black'> of a fact by you. <b>Please read these clauses carefully
before agreeing to these terms. Please note that all personal information will
be processed in accordance with our privacy notice. </b></span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><a name="_heading=h.gjdgxs"></a><b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;font-variant:small-caps;color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>terms applicable to using the website,
platform and services </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>These terms apply when you visit and/or use our website and
services. If you want to use the services, you will be required to register an
account and accept these terms, including the privacy notice. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We may amend these terms from time to time at our discretion,
including when our service offering expands and additional services and product
offerings become available. We will notify you of material changes with a
notice on the platform and you may be required to accept updated terms when
using the service. If you don't agree to any changes, you must stop using the
website or services. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;font-variant:small-caps;
color:black'>2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>In addition to these terms, our </span><a id="policy" lang=EN-ZA
style="font-size:11.0pt;line-height:150%;color:#0070C0;cursor:pointer;">privacy policy </a><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>governs how we
use your personal information. If there is a conflict between these terms and
the privacy policy, the terms of the privacy policy will prevail.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>registering an account</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>In order to use the services, you have to register an account by
following the steps on the website and providing the name of the individual
representing the startup, the startup’s name and an email address. The process
includes that a profile will then be created which can be accessed through a
username and password.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You agree that all information provided to us about the startup
and yourself as </span><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%'>an <span style='color:black'>individual user, is correct. As the
individual who registers on behalf of the startup, you guarantee that you have
the authority to enter into this agreement. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You further agree that the access details, including your username
and password login details</span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>,<span style='color:black'> will be kept safe at all times.
You are solely responsible </span>for keeping<span style='color:black'> your
account access details safe. If anyone enters your account access information
(whether that be you, or anyone else), we assume that the person using the
account is you and will not be liable for any losses suffered from the </span>unauthorized<span
style='color:black'> use of your account. You may not provide your account
login details to any other person or individual to access on behalf of the
startup without authority and must immediately inform AltSurge should you
suspect or know of </span>unauthorized<span style='color:black'> access of or
other security </span>concerns<span style='color:black'> related to your
account.</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>undertakings for using our website and
platform</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You may not:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>distribute any content from our website or platform without our
prior consent;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>use any technology, including but not limited to crawlers and
spiders to search our website or platform or obtain information from our
website or platform;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>frame our website, platform or any pages;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>link to our website or platform in a manner other than through the
homepage;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>deep-link to any other pages in a way that would suggest that you
own the intellectual property that belongs to us;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>provide us with any information which, to your knowledge, is
untrue or incorrect;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>change, modify, circumvent, disable or tamper with any part of our
website or platform, including its security features; and/or </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.1.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>use our website or platform for commercial purposes other than to
use our service, unless in terms of a written agreement signed between us and
yourselves.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You may only use our website and platform in the following ways:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>for the startup’s own internal use;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>in accordance with the limited</span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>,<span style='color:black'> revocable
license which we grant to you and which is subject to these terms;</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>4.2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>if you have the legal capacity to do so in terms of the laws of
the Netherlands; and/or</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.30j0zll"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>4.2.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>if </span><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%'>an <span style='color:black'>individual, you are 18 (eighteen) years and
older at the time of agreeing to these terms. If you are under the age</span> <span
style='color:black'>of 18 (eighteen), you may not register as a user on the
account.  AltSurge will not be liable for any losses suffered as a result of a
minor using the services in breach of these terms. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>using the altsurge service</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.1fob9te"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>As a registered user, you may request certain reports and </span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%'>engage with our AI to
perform certain services <span style='color:black'>through the platform. We
will confirm receipt of your request for a report or service through the
platform and only commence the service after we have received full payment for
it, should it </span>be a paid report or service<span style='color:black'>. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>A request for a report may not be </span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>canceled<span style='color:black'>
once it has been confirmed and paid. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We will confirm the pricing for the report when you request the
report. We may offer certain discounts from time to time in various formats in
our sole discretion.  </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>5.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You will be notified via email once the report is available in
your account. You will also be able to download a copy of the report. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>5.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>If we
are unable to process your request for a report for any reason, we will notify
you as soon as reasonably possible. If you have already made payment<span
style='color:black'>, we will refund the amount paid.</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>5.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You confirm that when providing your pitch de</span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%'>c<span style='color:black'>k
and other information through the platform, Altsurge will obtain an absolute
and unlimited right to use such information in perpetuity in its service
offering to other users, but always in a</span>n aggregated <span
style='color:black'>manner to ensure that no one user</span>’s information
shared through the service will not be identifiable<span style='color:black'>.
All information received from all users </span>is used to create algorithms to
enhance the service. The information will always be used in a de-identified and
anonymous format for this purpose. <span style='color:black'> </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>CANCELLATION AND SUSPENSION </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>AltSurge may suspend or terminate your access to the website
and/or platform and cancel the agreement with you without liability to you for
any costs that you have incurred if:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.1.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>we become aware of circumstances that lead us to believe that you
are or will be in breach of these terms; </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.1.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>you have misrepresented yourself as the individual or
misrepresented the startup as the legal entity; </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.1.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>you have used the website or platform to breach the intellectual
property rights of any third party; or</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.1.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>you have not made the required payments or have suspended any
payments due by you in terms of these terms to us.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>All our rights in respect of the confidentiality undertakings and
our limitation of liability as set out below will survive the termination of
these terms.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>On termination of the agreement:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>you must immediately cease all use of the website and platform;
and</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>6.3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>we will retain your personal information for a reasonable period
in line with the privacy policy. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><a name="_heading=h.3znysh7"></a><b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;font-variant:small-caps;color:black'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>Pricing and payment </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.2et92p0"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>The pricing for all reports are available through the platform.
All prices include any applicable taxes unless stated otherwise. To the extent
allowed under applicable laws, you are responsible for any applicable taxes.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Pricing is subject to change from time to time, however, changes
will not apply to existing requests. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Payments for a report may be made by credit card only. We are not
directly involved in the online payment process and </span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>use a third-party payment provider.
Once you proceed with payment, you will be redirected to the third-party
payment provider’s website to complete the payment. Please consider all
applicable terms and privacy notices of the third-party<span style='color:black'>
payment provider before proceeding with payment. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.tyjcwt"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>All payments </span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>will be made in either United States Dollars (outside the EU)
or <span style='color:black'>Euros (inside the EU).</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Upon payment, you will be presented with a confirmation screen
verifying the transaction details you wish to process. It is your responsibility
to verify that all transaction information and payment details are correct.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We have no liability for transactions which are incorrect as a
result of inaccurate information provided in the course of making payment or
for loss of data or information caused by factors beyond our control.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Once a payment has been made</span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>,<span style='color:black'> it cannot
be </span>canceled<span style='color:black'>. We do not accept any
responsibility for refusal or reversal of payments, which shall be a matter
between you and your credit card issuer.</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>When you complete the online payment form, funds will be deducted
from your credit card in either Euro or United States Dollar currency. All
payments are debited to AltSurge. There may be delays of up to </span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%'>seven<span
style='color:black'> days for the payment to reflect with us. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Credit card payments are secured by Secure Socket Layer (SSL)
technology and are protected with a high level of encryption when transmitted
over the Internet.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>AltSurge does not store credit card details.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Should payment for the report requested be unsuccessful, you will
be notified of this and will be able to attend to payment again. In the event
of a failed payment, your request will not be processed until it has been
successfully paid and AltSurge will not be liable for any opportunities that
are no longer available due to any failed payments.<b> As set out in
clause&nbsp;5.1 above, full payment is required before any request will be
processed.</b></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>7.12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>If you cancel a payment by giving instructions to your bank to
return your funds, and they do so, you will be liable to us for any penalty
which we incur to that bank or other payment processor.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>&nbsp;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><a name="_heading=h.3dy6vkm"></a><b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;font-variant:small-caps;color:black'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>ACCEPTABLE USE POLICY</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>8.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We make the website and platform available for your internal use
only. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>8.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Some devices may not support the use of our website or platform.
It is your responsibility to keep your device(s) updated and/or in a condition
for them to support the use of our website and platform, including internet
access capabilities.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>8.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You must respect our website, platform and our intellectual
property in utmost good faith and use it only as we intend it to be used. Any
use which violates this undertaking can result in us terminating your use of
our website and/or platform. We will be the sole judge of what constitutes a
violation of your undertaking in this regard. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>ELECTRONIC MESSAGES AND COMMUNICATION</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>9.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We will mainly use email and electronic notices on the website and
platform as our communication tool for all communications relating to your use
of the website, platform or these terms. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>9.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>By accepting these terms and using our website or platform, you
agree that we may use your personal contact information provided by you for
communicating with you <i>via </i>email. This includes us sending you direct
marketing communications. You can opt-out from receiving further direct
marketing messages at any point in time.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:22.5pt;text-indent:-22.5pt;page-break-after:avoid;border:none'><b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>SOCIAL MEDIA INTEGRATION</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:22.5pt;text-indent:-22.5pt;border:none'><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'>10.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>The website and platform may allow you to create an account,
profile, log in and/or share information through connecting to various social
media sites or apps, including Facebook, Pinterest, LinkedIn, and Instagram.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:22.5pt;text-indent:-22.5pt;border:none'><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'>10.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>If you proceed to use our website or platform in such context,
then the relevant social media websites’ rules will also apply, and you agree
to such integration.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>THIRD PARTY SITES</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>We may provide
links to third party websites or apps only for your convenience, and the
inclusion of any links or any advertisement of any third party on our website
or platform does not mean that we endorse their websites or apps, their
products, business or security practices or any association with its operators.
If you access and use any third-party websites, apps, products, services,
and/or business through our website or platform, you do that solely at your own
risk.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>Intellectual property and licenses </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>12.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Unless otherwise agreed between us, AltSurge and/or its licensors
own the intellectual property rights for all material on the website and
platform, as well as the intellectual property in the creation of and in the
reports. We provide you with a limited license to use </span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>the report for your internal business
purposes only, subject to these terms at all times. You may not share the
report outside of your business, other than with our express consent. <span
style='color:black'>All intellectual property rights are reserved for AltSurge
or its licensors. You may view pages from the website or platform for your own
internal use subject to restrictions set in these terms. You agree that you
will not acquire any rights of any nature in respect of that intellectual
property by using the website, platform or reports, other than the license in
terms of this agreement.</span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>12.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You must not:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>12.2.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>republish any material from the website, platform;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>12.2.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>reproduce, duplicate or copy material from the website, platform;
or</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>12.2.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>redistribute content from the website or platform (unless content
is specifically made for redistribution).</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>12.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You agree that you will not acquire any intellectual property
rights of any nature in respect of our intellectual property by using our
website, platform or receiving reports.</span></b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'> </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>WARRANTIES AND REPRESENTATIONS</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;color:black'>Subject to applicable laws:</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We give no guarantee of any kind concerning the content or quality
and uptime of our website or platform or reports presented through the service.
We do </span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>not
provide any guarantee on the content of the report or that it may result in the
start-up becoming more investible. </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We do not give any warranty (express or implied) or make any
representation that our website or platform will operate error free or without
interruption or that any errors will be corrected or that the content is
complete, accurate, up to date, or fit for a particular purpose.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We make no representations to you, either express or implied, and
we will have no liability or responsibility for the proper performance of the
website or platform and/or the information, images or audio contained on the
website or platform. <u>Our website and platform is used at your own risk</u>.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>13.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You warrant to and in favour of us that:</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.4.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>you have the legal capacity to agree to and be bound by these
terms; and/or</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.4.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>these terms constitute a contract valid and binding on you and
enforceable against you, both as individual and as the startup. </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>13.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Each of the warranties given by you will:</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.5.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>be a separate warranty and will in no way be limited or restricted
by inference from the terms of any other warranty or by any other words in
these terms;</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.5.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>continue and remain in force irrespective of whether these terms
are active, suspended or cancelled; and</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>13.5.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>be deemed to be material.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><a name="_heading=h.1t3h5sf"></a><b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;font-variant:small-caps;color:black'>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>LIMITED LIABILITIES</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.4d34og8"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>14.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>The provisions of this clause&nbsp;14 are subject to applicable
laws, and AltSurge’s liability will be limited to the extent allowed by
applicable laws.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We will not be liable for any loss, injury or death (except if
such loss, injury or death arises from the gross negligence or wilful
misconduct of AltSurge) arising from your use of the website or platform or
reports or any reliance on the information presented on the website or platform
or reports or provided by AltSurge as part of the website or platform.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We will not be liable to you for any loss, injury or death (except
if such loss, injury or death arises from the gross negligence or wilful
misconduct of AltSurge) caused by using our website or platform or your
liability to any third party arising from it. This includes but is not limited
to:</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.3.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>any interruption, malfunction, downtime, off-line situation or
other failure of the website, platform, system, databases or any of website’s
or platform’s components, or those services of our subcontractors;</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.3.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>any loss or damage regarding your data or other data directly or
indirectly caused by malfunction of the website or platform; and</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.3.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>any third-party systems whatsoever, power failures, unlawful
access to or theft of data, computer viruses or destructive code on the website
or platform, or third-party systems or programming defects.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We will not be liable if any material available for downloading
from the website or platform or provided by us is not free from infection,
viruses and/or other code that has contaminating or destructive properties.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>The website and platform may include inaccuracies – in such
instances we can’t be held liable and can’t be forced to comply with offers or
information that is genuinely and manifestly erroneous.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>We are not responsible for the proper and/or complete transmission
of the information contained in any electronic communication or of the
electronic communication itself, nor for any delay in its delivery or receipt.
Security measures have been implemented to ensure the safety and integrity of
our website and platform and all information processed by us. However, despite
this, information that is transmitted over the internet may be susceptible to
unlawful access and monitoring.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Our limited liability applies to all and any kind of loss which we
can possibly contract out of under law, including direct, indirect,
consequential, special, punitive  or other kinds of losses or claims which you
may suffer.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>14.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>If any matter results in a valid claim against AltSurge,
AltSurge’s liability will be limited to the total price paid by you in respect
of the report(s) requested.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>INDEMNITY</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;border:none'><b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>You
acknowledge that you use the website and platform and report on the explicit
condition that you hereby waive and release AltSurge (including our
shareholders, directors and employees) from any duty to yourself.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;border:none'><b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>You further
waive any actual or potential claim against AltSurge (including our
stockholders, directors and employees) as a result of any direct, indirect or
consequential damage (in the widest sense possible, whatsoever) relating to any
costs, loss, liability, damage or expenses (in the widest sense) (“claims”)
which you may suffer as a result of, or arising out of, using the website or
platform or report and you further indemnify and hold AltSurge harmless against
such claims.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-.25in;border:none'><b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Subject to
applicable laws, you further indemnify and hold us harmless against any claim
by any person for any costs, damages (including, without limitation, indirect,
extrinsic, special, penal, punitive, exemplary or consequential loss or damage
of any kind), penalties, actions, judgements, suits, expenses, disbursements, fines
or other amounts arising, whether directly or indirectly, from a breach of
these terms by you.</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>FORCE MAJEURE</span></b></p>

<p class=MsoNormal style='margin-left:35.45pt;border:none'><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'>Neither you nor we will
be liable if either of us cannot perform in terms of any agreed terms due to
reasons beyond our control. This includes lightning, flooding, exceptionally
severe weather, fire, explosion, war, civil disorder, industrial disputes, acts
or omissions of persons for which we are not responsible, and acts of
government or other competent authorities (including telecommunications and
internet service providers). </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><b><span lang=EN-ZA style='font-size:11.0pt;line-height:
150%;font-variant:small-caps;color:black'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
font-variant:small-caps;color:black'>GENERAL</span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.2s8eyo1"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>17.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Suspension of the website and/or platform:</span></b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'> we may
temporarily suspend the website or platform for any reason, including repairs
or upgrades to the website or platform or other systems. We will take
reasonable efforts to notify you of such suspensions in advance through the
website and/or platform. We may also in our discretion decide to permanently
suspend the website or platform and close it down. Any such decision shall not
affect any existing orders. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Entire agreement:</span></b><span lang=EN-ZA style='font-size:
11.0pt;line-height:150%;color:black'> these terms are the whole agreement
between the parties relating to the subject matter of these terms and
supersedes any other discussions, agreements and/or understandings regarding
the subject matter of these terms.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Confidentiality:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> neither party will disclose any confidential
information to any third party without the prior written approval of the other
party, unless required by law. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Law and jurisdiction:</span></b><span lang=EN-ZA style='font-size:
11.0pt;line-height:150%;color:black'> these terms and all obligations connected
to them or arising from them shall be governed and interpreted in terms of the
laws of the Netherlands.  </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Good faith:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> the parties shall in their dealings with each
other display good faith.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><a
name="_heading=h.17dp8vu"></a><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>17.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Breach:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> if either party breaches any material provision
or term of these terms and fails to remedy such breach within 7&nbsp;(seven)
calendar days of receipt of written notice requiring it to do so then the
aggrieved party will be entitled without notice, in addition to any other
remedy available to it at law or under these terms (including obtaining an
interdict), to cancel these terms or to claim specific performance of any
obligation whether or not the due date for performance has arrived, in either
event without prejudice to the aggrieved party's right to claim damages.
Termination of these terms in terms of this clause&nbsp;17.6 will not result in
a refund unless so required by law. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>No waiver:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> the failure of AltSurge to insist upon or
enforce strict performance by you of any provision of these terms, or to
exercise any right under these terms, will not be interpreted as a waiver or
relinquishment of AltSurge’s right to enforce any such provision or right in any
other instance.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>No assignment:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> you will not be entitled to cede your rights or
delegate your obligations in terms of these terms without the express prior
written consent of AltSurge.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Relationship between the parties:</span></b><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'> the parties agree that
neither party is a partner or agent of the other party and neither party will
have any right, power, or authority to enter into any agreement for, or on
behalf of, or incur any obligation or liability of, or to otherwise bind, the
other party.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>No representation:</span></b><span lang=EN-ZA style='font-size:
11.0pt;line-height:150%;color:black'> to the extent permissible by law, no
party will be bound by any express or implied or tacit term, representation,
warranty, promise or the like not recorded herein, whether it induced the
contract and/or whether it was negligent or not.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Severability:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> any provision in these terms which is or may
become illegal, invalid or unenforceable will be ineffective to the extent of
such prohibition or unenforceability and shall be severed from the balance of
these terms, without invalidating the remaining provisions of these terms.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>No stipulation:</span></b><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'> no part of these terms will constitute a
stipulation in favour of any person who is not a party to these terms unless
the provision in question expressly provides that it does constitute such a
stipulation.</span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;page-break-after:
avoid;border:none'><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>17.13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Notices: </span></b></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.13.1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>AltSurge selects Korte Poellaan 12, 2012VT, Haarlem as its
physical address and web</span><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>site.terms<span style='color:black'>@altsurge.ai as its email
address for the service of all formal notices and legal processes in connection
with these terms, which may be updated from time to time by updating these
terms. </span></span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.13.2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>You hereby select the email address specified during the
registration of a registered user as your address for service of all formal
notices and legal processes in connection with these terms, which may be
changed by providing AltSurge with 7&nbsp;(seven) calendar days' notice in
writing. </span></p>

<p class=MsoNormal style='margin-left:22.5pt;text-indent:-22.5pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>17.13.3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Service <i>via</i> email shall be accepted in all cases where
notice is required unless alternative service is required by law. Service <i>via</i>
email is deemed to be received at the time and day of sending.</span></p>

</div>

</body>

</html>

`
  const rawHTML = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;}
@font-face
	{font-family:"Arial Narrow";
	panose-1:2 11 6 6 2 2 2 3 2 4;}
@font-face
	{font-family:"Arial Bold";
	panose-1:2 11 7 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	text-align:justify;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA1FirstLevelHeading, li.DA1FirstLevelHeading, div.DA1FirstLevelHeading
	{mso-style-name:"DA 1 First Level Heading";
	mso-style-link:"DA 1 First Level Heading Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:35.45pt;
	text-align:justify;
	text-indent:-35.45pt;
	line-height:150%;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial Bold";
	text-transform:uppercase;
	font-weight:bold;}
p.DA1FirstLevelUnnumberedParagraph, li.DA1FirstLevelUnnumberedParagraph, div.DA1FirstLevelUnnumberedParagraph
	{mso-style-name:"DA 1 First Level Unnumbered Paragraph";
	mso-style-link:"DA 1 First Level Unnumbered Paragraph Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:35.45pt;
	text-align:justify;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.DA1FirstLevelHeadingChar
	{mso-style-name:"DA 1 First Level Heading Char";
	mso-style-link:"DA 1 First Level Heading";
	font-family:"Arial Bold";
	text-transform:uppercase;
	font-weight:bold;}
p.DA2SecondLevelNumbering, li.DA2SecondLevelNumbering, div.DA2SecondLevelNumbering
	{mso-style-name:"DA 2 Second Level Numbering";
	mso-style-link:"DA 2 Second Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:78.0pt;
	text-align:justify;
	text-indent:-42.55pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.DA1FirstLevelUnnumberedParagraphChar
	{mso-style-name:"DA 1 First Level Unnumbered Paragraph Char";
	mso-style-link:"DA 1 First Level Unnumbered Paragraph";
	font-family:"Arial",sans-serif;}
p.DA3ThirdLevelNumbering, li.DA3ThirdLevelNumbering, div.DA3ThirdLevelNumbering
	{mso-style-name:"DA 3 Third Level Numbering";
	mso-style-link:"DA 3 Third Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:127.6pt;
	text-align:justify;
	text-indent:-49.65pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.DA2SecondLevelNumberingChar
	{mso-style-name:"DA 2 Second Level Numbering Char";
	mso-style-link:"DA 2 Second Level Numbering";
	font-family:"Arial",sans-serif;}
p.DA4FourthLevelNumbering, li.DA4FourthLevelNumbering, div.DA4FourthLevelNumbering
	{mso-style-name:"DA 4 Fourth Level Numbering";
	mso-style-link:"DA 4 Fourth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:177.2pt;
	text-align:justify;
	text-indent:-49.6pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.DA3ThirdLevelNumberingChar
	{mso-style-name:"DA 3 Third Level Numbering Char";
	mso-style-link:"DA 3 Third Level Numbering";
	font-family:"Arial",sans-serif;}
p.DA5FifthLevelNumbering, li.DA5FifthLevelNumbering, div.DA5FifthLevelNumbering
	{mso-style-name:"DA 5 Fifth Level Numbering";
	mso-style-link:"DA 5 Fifth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:233.9pt;
	text-align:justify;
	text-indent:-56.7pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.DA4FourthLevelNumberingChar
	{mso-style-name:"DA 4 Fourth Level Numbering Char";
	mso-style-link:"DA 4 Fourth Level Numbering";
	font-family:"Arial",sans-serif;}
p.DA6SixthLevelNumbering, li.DA6SixthLevelNumbering, div.DA6SixthLevelNumbering
	{mso-style-name:"DA 6 Sixth Level Numbering";
	mso-style-link:"DA 6 Sixth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:297.7pt;
	text-align:justify;
	text-indent:-63.8pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA7SeventhLevelNumbering, li.DA7SeventhLevelNumbering, div.DA7SeventhLevelNumbering
	{mso-style-name:"DA 7 Seventh Level Numbering";
	mso-style-link:"DA 7 Seventh Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:311.85pt;
	text-align:justify;
	text-indent:-70.8pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA8EighthLevelNumbering, li.DA8EighthLevelNumbering, div.DA8EighthLevelNumbering
	{mso-style-name:"DA 8 Eighth Level Numbering";
	mso-style-link:"DA 8 Eighth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:318.95pt;
	text-align:justify;
	text-indent:-77.95pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DA9NinthLevelNumbering, li.DA9NinthLevelNumbering, div.DA9NinthLevelNumbering
	{mso-style-name:"DA 9 Ninth Level Numbering";
	mso-style-link:"DA 9 Ninth Level Numbering Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:326.05pt;
	text-align:justify;
	text-indent:-85.05pt;
	line-height:150%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
.MsoChpDefault
	{font-size:10.0pt;
	font-family:"Arial",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	text-align:justify;
	line-height:150%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<div style='border:none;border-bottom:solid black 1.0pt;padding:31.0pt 31.0pt 1.0pt 31.0pt'>

<p class=MsoNormal style='page-break-after:avoid;border:none;padding:0in'><b><span
lang=EN-ZA style='font-size:15.0pt;line-height:150%;font-variant:small-caps;
color:black'>privacy policy- altsurge platform</span></b></p>

<p class=MsoNormal style='page-break-after:avoid;border:none;padding:0in'><b><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;font-variant:small-caps;
color:black'>Last updated: may 2024</span></b></p>

</div>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>what
does this policy do?</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>This
privacy policy (“policy”) explains how AltSurge Netherlands B.V (“AltSurge”,
“us” or “we”) collect and use personal data.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>It
contractually regulates the rights of AltSurge when processing personal data of
users of the platform (“Platform”) available on AltSurge’s website (“Website”) 
and Website users when visiting our Website and/or use the Platform, and all
rights in this regard. Any reference to “you” means the person whose personal
data is being or may be processed. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Please
read this policy carefully before using the AltSurge Platform, Website and use
the Services.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Legally
binding contract</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>This
policy will apply to you, and will create a legally binding contract between
us, whenever you use or access our Website or Platform.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Other
applicable terms</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>There
are also Website and Platform terms of service that apply to a Website and/or
Platform user’s use of our Website and/or Platform. Additional terms may also
apply for specific products or services or subscriptions available through our
Website and/or Platform. If there is a conflict between these terms and the
specific terms, the specific terms will apply. References to “Services” means
the services as set out on the Website and Platform terms of service. </span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>changes
to this policy</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Unless
notice is required in terms of the law, we may change the terms of this policy
from time to time in line with any changes that the law or our internal
business operations require, without prior notice. The current version of this
policy that applies at the time you visit and/or use our Website and/or
Platform or we process your information, will regulate our relationship. It is
your responsibility to consider the policy each time you use our Website and/or
Platform. <b>If you do not agree with any terms of this policy, you must stop
using our Website and/or Platform</b>.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>our
commitment</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>This
policy is important to us. We are committed to taking steps to protect your
privacy when you use our Website and/or Platform or when we process personal
data and are committed to implementing business practices that comply with all
relevant data protection legislation, including the General Data Protection
Regulation ((EU) 2016/679) (“<b>GDPR</b>”). In this policy, we explain how we
will use and protect your personal data.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>what
is personal data and what personal data do we collect</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>If we
refer to “personal data” or “information” in this policy, we mean personal data
as defined in the GDPR. The categories of personal data that we may collect are
listed below.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>General
personal details: your name and surname, passport number and the information
about your business.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Contact
details: your address, contact number, and email address.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>User
information: personal data included in correspondence, transaction documents,
use of the Services or other materials that we process in the course of
providing the Services to you.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Consent
records: records of any consents you have given us in respect of using your
personal data and any related information, such as the specific details of the
consent. We will also record any withdrawals or refusals of consent.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Payment
details: payment method, information provided by payment gateway service provider,
payment amount, date and reason for payment and related information.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Data
relating to our Website: data such as the type of device you use to access the
Website, the operating system and browser, browser settings, IP address, dates
and times of connecting to and using the Website and other technical
communications information. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Cookies
and other technologies.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Account
details: such as your username, password, login details, usage data, and
aggregate statistical information.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Content
and advertising data: records of your interactions with our online advertising
on the various websites which we advertise and records relating to content
displayed on web-pages displayed to you.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>6.11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Views
and opinions: any views and opinions that you choose to share with us, or
publicly post about us on social media platforms or elsewhere.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>collecting
your personal data</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
collect information about you when you use our Website and/or Platform and when
you communicate with us.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We may
also collect information about you from third parties if the law allows it or
if the information is publicly available. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>7.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
also collect information through cookies as set out below in clause 8. </span></p>

<p class=DA1FirstLevelHeading><a name="_heading=h.gjdgxs"></a><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%;color:black'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>use of
cookies</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>When
you use the Website, we automatically receive and record information on our
server logs from your browser. This information may include, amongst others,
your location, IP address, cookie information and Google Analytics information.
This is statistical data about browsing actions and patterns. We may also
obtain information about your general internet usage through a cookie file
which is stored on the hard drive of your computer. Cookies enable us to
improve our Website and Services, estimate our audience size and usage
patterns, store information about your preferences and recognise when you
return to our Website.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>In
some instances, we collect and store information about your location through
cookies (other than when you share your location with us). We convert your IP
address or mobile GPS data into a rough geo-location and we may use location
information to improve and personalise our Website and Services for you. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>You
can set your web browser to refuse cookies, but if you do this you may not be
able to enjoy the full use of our Website or Services and you may not be able
to take advantage of certain promotions we may run. We only process cookies in
compliance with this Policy and any cookies that include Personal Information
will be treated accordingly. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Please
note that the third party payment provider used to make payment for our
services may also use cookies, but we do not have access to, or control over
them.      </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>The
various types of cookies (distinguished by their function, lifespan and origin)
that we may collect include the following: </span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>First
party cookies. Cookies that are stored by the actual website or app you are
visiting.  </span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Necessary
cookies. Cookies that are necessary for the technical operation of a website or
app. For example, they enable you to move around on a website or app and to use
its features.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Performance
cookies. Cookies that collect data on the performance of a website or app.
Examples include the number of persons who visit a website or app, the time
spent on the website or app and errors that may arise during the use of the
website or app.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Functionality
or preference cookies. Cookies that increase the usability of a website or app
by remembering a visitor's choices, such as the language preference, login
information, location of the visitor, etc. </span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Targeting
or advertising cookies. Cookies that enable a website or app to send its
visitors personalised advertising, often based on your browsing history.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.5.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Session
cookies. These are temporary cookies that are deleted once you close the
browser. Permanent cookies are those that are stored on your device until you
delete them or until your browser deletes them based (after a period specified
in the cookie).</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>8.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>You
can set your web browser to refuse cookies, but if you do this you may not be
able to enjoy the full use of our Website and/or Platform and you may not be
able to take advantage of certain promotions we may run.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>links
on our website and platform</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>9.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Our
Website and/or Platform may include links to other apps or third party websites
which do not fall under our supervision. We cannot accept any responsibility
for your privacy or the content of these apps, but we display these links to
make it easier for our visitors to find information about specific subjects.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>how we
use your information</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
will only process adequate and relevant information to:</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Operate
and manage your account.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Process
your information for the purposes of providing the Services.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Monitor
and analyse our business.</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.1.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Contact
you by email, SMS, or other means to tell you about our services (you can
always opt out from future marketing).</span></p>

<p class=MsoNormal style='margin-left:127.6pt;text-indent:-49.65pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.1.5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Form a view of
you as an individual or the Company you represent and to identify, develop or
improve services that may interest you.</span></p>

<p class=MsoNormal style='margin-left:127.6pt;text-indent:-49.65pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.1.6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Carry out
market research, business and statistical analysis.</span></p>

<p class=MsoNormal style='margin-left:127.6pt;text-indent:-49.65pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.1.7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Carry out
audits.</span></p>

<p class=MsoNormal style='margin-left:127.6pt;text-indent:-49.65pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.1.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Perform other administrative
and operational tasks like testing our processes and systems.</span></p>

<p class=MsoNormal style='margin-left:127.6pt;text-indent:-49.65pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>10.1.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>Comply with
our regulatory or other obligations.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We may
use your information for marketing purposes to market our own similar products
or services to you.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We may
share your information with third parties if part of the Services requires us
to share information with third parties. We may otherwise share your
information with third parties as provided for in this policy. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>10.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We may
use your information for other purposes if the law allows for it, or if you
consent to it, or if it is in the public interest.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>your
legal rights</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=DA2SecondLevelNumberingChar><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>You have certain rights in relation
to your personal data. As available and except</span></span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'> as limited under applicable laws,
you have the following rights in respect of your personal data:</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
of access – the right to be informed of and request access to the personal data
that we process about you;</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
to rectification – you may request that your personal data be amended or
updated where it is inaccurate or incomplete;</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
to erasure – the right to request that we delete your personal data, subject to
applicable limitations and exceptions;</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
to restrict processing – you may request that we temporarily or permanently
stop processing your personal data;</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
to object – </span></p>

<p class=DA4FourthLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>11.1.5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>you
may object to us processing your personal data; and</span></p>

<p class=DA4FourthLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>11.1.5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>to
your personal data being processed for direct marketing purposes;</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
to information portability – you may request a copy of your personal data and
request that information to be transmitted for use by another person; and</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.1.7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Right
not to be subject to automated decision-making – where a decision that has a
legal or other significant effect is based solely on automated decision making,
including profiling, you may request that your personal data not be processed
in that manner.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>11.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Where
you have provided consent for us to process your personal data, you may also
withdraw your consent where our processing is based on your consent. However,
we may continue to process your personal data if another legal justification
exists for the processing.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>lodging
a complaint</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>12.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>If you
believe we are using your information unlawfully, you may lodge a complaint to
the following contact details:</span></p>

<p class=DA3ThirdLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>12.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Complaints
to the European Commission:</span></p>

<p class=DA4FourthLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>12.1.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Online complaint procedure: </span><span lang=EN-ZA><a
href="https://ec.europa.eu/info/about-european-commission/contact/problems-and-complaints/how-make-complaint-eu-level/submit-complaint_en"><span
style='font-size:11.0pt;line-height:150%;color:#0563C1'>https://ec.europa.eu/info/about-european-commission/contact/problems-and-complaints/how-make-complaint-eu-level/submit-complaint_en</span></a></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>  </span></p>

<p class=DA4FourthLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>12.1.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Address: European Commission, Secretary-General B-1049 Brussels,
BELGIUM</span></p>

<p class=DA4FourthLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%'>12.1.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%;
color:black'>Fax: 3222964335</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>children’s
information and sENSITIVE personal data</span></p>

<p class=DA1FirstLevelUnnumberedParagraph><span lang=EN-ZA style='font-size:
11.0pt;line-height:150%'>We do not intentionally collect or use information of
children (persons under the age of 18 (eighteen) years) or information that
qualify as sensitive data, unless with consent. </span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>sharing
of personal data</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>14.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
will keep your personal data confidential and only share it with others in
terms of this policy, or if you consent to it, or if the law requires from us
to share it. We have trusted relationships with carefully selected third
parties who perform services for us. All these service providers have a
contract with us in terms whereof they have a legal obligation to secure your
personal data and to use it only in a way that we permit.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>14.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>In
general, we may share your information with our affiliates, third party service
providers, regulatory and governmental authorities or tax authorities (if they
request us to do so), and any other third party if the law requires from us to
share it.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>14.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>Our
Website and/or Platform may connect to various social media sites or apps,
including Facebook, Twitter, LinkedIn, Instagram and Google+. If you want to
use our Website and/or Platform for social media integration, including to
create user profiles and login functionality, we will share your information
with the relevant social media sites or apps. </span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>how
secure is your information?</span></p>

<p class=DA1FirstLevelUnnumberedParagraph><span lang=EN-ZA style='font-size:
11.0pt;line-height:150%'>We are committed to implement appropriate technical
and other security measures to protect the integrity and confidentiality of
your information. We protect and manage information that we hold about you, by
using electronic and computer safeguards like data encryption. We only
authorise access to information to those employees who require it to fulfil
their designated responsibilities.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>quality
and access to your information</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>16.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
want to ensure that your information is accurate and up to date. You may ask us
to correct or remove any information that you think is inaccurate, by sending
us an email to data</span><span lang=EN-ZA><a href="mailto:llew.ce@altsurge.ai"><span
style='font-size:11.0pt;line-height:150%;color:#0563C1'>@altsurge.ai</span></a></span><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%'>. </span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>16.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>You
have the right to request us to provide you with information that we hold about
you. You must contact us directly to do so or send an email to data</span><span
lang=EN-ZA><a href="mailto:llew.ce@altsurge.ai"><span style='font-size:11.0pt;
line-height:150%;color:#0563C1'>@altsurge.ai</span></a></span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>retention
of information</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>17.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
retain information in accordance with the required retention periods in law or
for legitimate business purposes. We will only retain your information for the
purposes explicitly set out in this policy. We may keep information
indefinitely in a de-identified format for statistical purposes.</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>17.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>This
policy also applies when we retain your information.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>18.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>trans-border
flow of information</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>18.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=DA2SecondLevelNumberingChar><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>W</span></span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>e may transfer your information to
foreign countries mainly for retention purposes or if our service providers are
cross border or use systems cross border. If you continue to use our Website
and/or Platform, you expressly agree that we may transfer the information cross
border for these purposes. We will only share information cross border to
recipients who have similar laws to the GDPR that apply to them, or who entered
into an agreement with us to include the relevant protection principles.</span></p>

<p class=DA1FirstLevelHeading><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>19.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>security
breach</span></p>

<p class=DA2SecondLevelNumbering><span lang=EN-ZA style='font-size:11.0pt;
line-height:150%;color:black'>19.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-ZA style='font-size:11.0pt;line-height:150%'>We
will report any security breach to the relevant authority and to the
individuals or companies involved. If you want to report any concerns about our
privacy practices or if you suspect any breach regarding your information,
kindly notify us by sending an email to data</span><span lang=EN-ZA><a
href="mailto:llew.ce@altsurge.ai"><span style='font-size:11.0pt;line-height:
150%;color:#0563C1'>@altsurge.ai</span></a></span><span lang=EN-ZA
style='font-size:11.0pt;line-height:150%'>.</span></p>

<p class=MsoNormal style='margin-left:78.0pt;text-indent:-42.55pt;border:none'><span
lang=EN-ZA style='font-size:11.0pt;line-height:150%;color:black'>&nbsp;</span></p>

</div>

</body>

</html>
`

  const [currentTitle, setCurrentTitle] = useState("Terms and Conditions")
  const [currentHTML, setCurrentHTML] = useState(rawHTMLTerms)

  useEffect(() => {
    const listenPolicyClick = (e) => {
      console.log(e.srcElement.id)
      if (e.srcElement.id === 'policy') {
        handlePolicyLinkClick()
      }
    }

    const handlePolicyLinkClick = () => {
      setCurrentHTML(rawHTML)
      setCurrentTitle("Privacy Policy")
    }

    document.addEventListener('click', listenPolicyClick)

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('DOMContentLoaded', listenPolicyClick)
    }
  }, [rawHTML])

  return (
    <div className='card mb-5 mb-xl-10 mx-6'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='fv-row'>
            {/* begin::Text */}
            <div className='fs-base fs-3 fw-semibold px-2 pb-8'>
              <h1>
                <strong>{currentTitle}</strong>
              </h1>

              <div>{<div dangerouslySetInnerHTML={{__html: currentHTML}} />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
