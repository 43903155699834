export * from './AssetHelpers'
export * from './RouterHelpers'
export * from './components/KTSVG'
export * from './components/KTIcon'
export * from './components/KTCard'
export * from './components/KTCardBody'
export * from './dataExamples'
export * from './crud-helper/helpers'
export * from './crud-helper/models'
export * from './crud-helper/consts'
export * from './react18MigrationHelpers'

const dispatchStorageEvent = (key, value) => {
  const event = new StorageEvent('storage', {
    key,
    newValue: value,
    oldValue: localStorage.getItem(key),
    storageArea: localStorage,
  });
  window.dispatchEvent(event);
};

export {dispatchStorageEvent}
