/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

export function HomePage() {
  return (
    <>

    </>
  )
}
