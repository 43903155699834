/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Reports} from './Reports'

const ReportView: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8' style={{marginTop: `-60px`}}>
      <div className='col-xl-12'>
        <Reports />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const ReportOverview: FC = () => {
  return (
    <>
      <ReportView />
    </>
  )
}

export {ReportOverview}
