/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserModel, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import IPConfig from '../../../store/IPConfig'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [currentSite, setCurrentSite] = useState('io')
  const {saveAuth, setCurrentUser} = useAuth()
  const { search } = useLocation();
  const apiUrl = IPConfig('metis');

  useEffect(() => {
    localStorage.setItem('currentSite', 'alt');
    setCurrentSite('alt');
    const body = document.getElementById('kt_app_body')
    if (body) {
      body.style.backgroundColor = '#0E252F'
    }
    return () => {
      if (body) {
        body.style.backgroundColor = '#0E252F'
      }
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(search);

    // verify
    const verify = params.get('verify');
    if (verify && verify === 'true') {
      const token = params.get('token');
      if (token) {
        verifyUser(token);
      }
    }
  }, [search]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        const auth: any = await login(values.email, values.password);

        if (typeof auth !== "undefined" && auth !== null && auth.data) {
          localStorage.setItem("usertoken", auth.data.data?.token);
          localStorage.setItem("user_id", auth.data.data?.id);
          saveAuth(auth.data);
          const user = getUserModel(auth.data);
          setCurrentUser(user);
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const verifyUser = (token) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("token", token);

      fetch(`${apiUrl}/verify-user`, {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(response => {
          setLoading(false)
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  };

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-light fw-bolder mb-3'>Sign In</h1>
        <div className='text-white fw-semibold fs-6'>Login to your account</div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-white fw-bold'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-white fw-bold'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-secondary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className={currentSite === "alt" ? 'btn alt-bg-purple text-white' : 'btn btn-primary'}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

    </form>
  )
}
