import React, {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface StepProps {
  responseError: string;
  responseSuccess: string;
  setFilesToAdd: (files: Array<any>) => void;
  filesToAdd: Array<any> | null;
  stepper: any
  loading: boolean
  documentUploaded: boolean
}

const Step2Reprocess: React.FC<StepProps> = ({
  responseError,
  responseSuccess,
  setFilesToAdd,
  filesToAdd,
  stepper,
  loading,
  documentUploaded,
}) => {
  const [uploadError, setUploadError] = useState<string | null>(null)

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError(null)
    const files: FileList | null = e.target.files
    if (files && files.length > 0) {
      const file = files[0]
      const fileType = file.type
      const fileName = file.name.toLowerCase()
      const fileSize = file.size

      // Check file type
      if (!(fileType === 'application/pdf' || fileName.endsWith('.pdf') || fileName.endsWith('.md'))) {
        setUploadError('Invalid file type. Please upload a .pdf or .md file.')
        return
      }

      // Check file size (5 MB = 5 * 1024 * 1024 bytes)
      if (fileSize > 5 * 1024 * 1024) {
        setUploadError('File size exceeds 5 MB. Please upload a smaller file.')
        return
      }

      const newArray: any = filesToAdd ? [...filesToAdd] : []
      newArray.push(file)
      setFilesToAdd(newArray)
    }
  }

  return (
    <div className="container">
      <div className="row text-white">
        <div className="col-12 text-center mb-5 mt-5">
          <h2 className="fw-bold fs-2qx fs-lg-2qx mw-1000px text-uppercase m-auto text-white">
            Provide us with a new file to <span className="fw-bolder alt-purple">process your report</span>
          </h2>
          <p className="fs-5 fs-lg-2 mt-4 mt-lg-8 mw-1000px m-auto">
            View your report on our system after it has been generated.
          </p>
        </div>
      </div>

      <div className="fv-row">

        <div className="card mb-5 mb-xl-10 alt-bg-gunmetal mw-650px m-auto mt-10" id="kt_profile_details_view">
          <div className="card-body p-9">

            <div className="fv-row mb-6">
              <label className="form-label fw-bolder text-light fs-6 float-start">
                Upload Document (Max 5 MB)
              </label>

              <span className="alt-white fw-bold float-end">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="uploadGuidelines">
                      1. Please upload a PDF file<br />
                      2. Documents with lots of imagery will take longer to process<br />
                      3. Complex graphics may not be well interpreted by the AI<br />
                      4. No more than 20 pages/slides
                    </Tooltip>
                  }
                >
                  <span>
                    <KTIcon
                      iconName="information"
                      className="d-block float-start fs-3 ms-2 mt-0 me-1"
                    />
                    Guidelines for document upload
                  </span>
                </OverlayTrigger>
              </span>

              <input
                className="form-control form-control-solid choose-file"
                type="file"
                accept=".pdf, .md"
                onChange={handleFileSelect}
              />
            </div>

            {uploadError && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-danger fw-bold">
                  <span role="alert">{uploadError}</span>
                </div>
              </div>
            )}

            {responseError && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-danger fw-bold">
                <span role="alert">{responseError}</span>
                </div>
              </div>
            )}

            {responseSuccess && (
              <div className="fv-plugins-message-container pb-6 mt-6">
                <div className="fv-help-block text-success fw-bold">
                  <span role="alert">{responseSuccess}</span>
                </div>
              </div>
            )}
            <button
              disabled={stepper?.current?.currentStepIndex === 2 && documentUploaded}
              type="submit"
              className="btn btn-lg mt-4 alt-bg-purple text-white me-3"
            >
              {!loading && (
                <div>
                  <div className="indicator-label float-start mt-0">
                    <span id="nextButtonLabel">
                      {stepper?.current?.currentStepIndex === 2 &&
                        documentUploaded &&
                        'Document Uploaded'}
                      {stepper?.current?.currentStepIndex === 2 &&
                        !documentUploaded &&
                        'Upload Document'}
                    </span>
                  </div>
                  <div className="float-end" style={{paddingTop: '2px'}}>
                    <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                  </div>
                </div>
              )}
              {loading && (
                <span className="indicator-progress" style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export {Step2Reprocess}
