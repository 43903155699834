/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayoutAlt = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 ps-10 pe-10 pb-10 order-1 order-lg-1'>
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">

          {/* begin::Logo */}
          <div className={'fs-7qx'}>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/alt-logo-full.png')} className='w-250px w-sm-250px w-md-300px w-lg-500px' />
          </div>
          {/* end::Logo */}

          {/* begin::Title */}
          <div className="separator separator-content separator-alt mt-2 mb-20 px-20 w-100">
            <span className="w-500px w-sm-400px w-md-600px w-lg-1000px w-xl-550px alt-night fw-bold fs-2qx">COMING SOON</span>
          </div>
          {/* end::Title */}

          {/* begin::Text */}
          <div className="alt-night fs-base text-center fs-2 fw-semibold pb-10">
            Thank you for your interest in AltSurge, an AI-powered, <br /> high-performance co-pilot for startup
            founders and their investors.
          </div>
          {/* end::Text */}

          {/* begin::Text */}
          <div className="alt-night fs-base text-center fs-2 fw-semibold pb-8 mb-8">
            We are currently in a closed alpha release with early startup users. <br /> To join the waiting list, please
            complete the form.
          </div>
          {/* end::Text */}

        </div>
        {/* end::Form */}

      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2 alt-bg-blue-light alt-border-blue-left'
        /*style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}*/
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>

          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}

        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayoutAlt}
