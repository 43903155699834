/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {ChatInner} from '../../../_metronic/partials'
import IPConfig from '../../store/IPConfig'
import {Spinner} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import clsx from 'clsx'

const Reports: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isChatEnabled, setIsChatEnabled] = useState(false)
  const [scoreContent, setScoreContent]: any = useState(null)
  const [exportContent, setExportContent]: any = useState(null)
  const [uploadedFile, setUploadedFile] = useState('')
  const [reports, setReports]: any = useState<[]>([])
  const [userId, setUserId] = useState<any>(null)

  const [reportStatus, setReportStatus] = useState('');
  const [cancelledNote, setCancelledNote] = useState("no reason")
  const [documentReportId, setDocumentReportId] = useState(() => localStorage.getItem('selectedReportContent') || null)
  const [documentScoreId, setDocumentScoreId] = useState(() => localStorage.getItem('selectedScoreContent') || null)
  const [selectedReportName, setSelectedReportName] = useState(() => localStorage.getItem('selectedReportName') || 'Pitch Readiness Report')
  const [selectedSourceId, setSelectedSourceId] = useState(() => localStorage.getItem('selectedSourceId') || null)
  const [selectedJobId, setSelectedJobId] = useState(() => localStorage.getItem('selectedJobId') || null)
  const [selectedPersonaName, setSelectedPersonaName] = useState(() => localStorage.getItem('selectedPersonaName') || 'Chatbot')
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState(() => localStorage.getItem('selectedPersonaAvatar') || '')

  useEffect(() => {
    // @ts-ignore
    setUserId(window.localStorage.getItem('user_id'))
  }, [])

  const publicUrl = IPConfig('public')
  const altApiUrl = IPConfig('metis')
  const apiUrl = IPConfig()

  useEffect(() => {
    const body = document.getElementById('kt_app_body')
    if (body) {
      body.style.backgroundColor = '#FFFFFF'
    }
    return () => {
      if (body) {
        body.style.backgroundColor = '#FFFFFF'
      }
    }
  }, [])

  useEffect(() => {
    setScoreContent(null)
    setExportContent(null)
    setDocumentReportId(null)
    setDocumentScoreId(null)
    setSelectedSourceId(null)
    setSelectedJobId(null)
    setReportStatus('')

    setSelectedPersonaName('Chatbot')
    setSelectedPersonaAvatar('')

    setIsLoading(true)
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedReportContent') {
        setDocumentReportId(event.newValue || null)
        setExportContent(event.newValue)
        setIsLoading(false)
      } else if (event.key === 'selectedScoreContent') {
        setDocumentScoreId(event.newValue || null)
        setScoreContent(event.newValue)
      } else if (event.key === 'selectedReportName') {
        setSelectedReportName(event.newValue || 'Pitch Readiness Report')
      } else if (event.key === 'selectedSourceId') {
        setSelectedSourceId(event.newValue || null)
      } else if (event.key === 'selectedJobId') {
        setSelectedJobId(event.newValue || null)
      } else if (event.key === 'selectedPersonaName') {
        setSelectedPersonaName(event.newValue || 'Chatbot')
        fetchChatbotEnabled()
      } else if (event.key === 'selectedPersonaAvatar') {
        setSelectedPersonaAvatar(event.newValue || '')
      } else if (event.key === 'setLoadingReport') {
        setIsLoading(true)
      } else if (event.key === 'setChatEnabled') {
        setIsChatEnabled(true)
      } else if (event.key === 'setChatDisabled') {
        setIsChatEnabled(false)
      }

      if (event.key === 'selectedReportStatus') {
        setReportStatus(event.newValue || '');
      }

      if (event.key === 'selectedCancelledNote') {
        setCancelledNote(event.newValue || 'no reason provided');
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    fetchReports()
    fetchFile()
    fetchChatbotEnabled()
  }, [documentReportId, userId])

  const fetchFile = () => {
    if (reports) {
      if (documentReportId) {
        fetch(`${apiUrl}/export?file_id=` + documentReportId)
          .then(response => response)
          .then(response => {
            setExportContent(documentReportId)
            if (documentScoreId) {
              setScoreContent(documentScoreId)
            }
            setIsLoading(false)
          })
          .catch(function(err) {
            console.log('Unable to fetch -', err)
          })
      } else {
        if (reports.length > 0) {
          let report = reports[0]
          if (report.saved_documents && report.saved_documents[0] && report.saved_documents[0].id && report.saved_documents[0].primary) {
            fetch(`${apiUrl}/export?file_id=` + report.saved_documents[0].id)
              .then(response => response)
              .then(response => {
                setExportContent(report.saved_documents[0].id)
                if (report.saved_documents[1] && report.saved_documents[1].id) {
                  setScoreContent(report.saved_documents[1].id)
                }
                setIsLoading(false)
              })
              .catch(function(err) {
                console.log('Unable to fetch -', err)
              })
          } else {
            setIsLoading(true)
          }
        } else {
          setIsLoading(true)
        }
      }
    } else {
      setIsLoading(true)
    }
  }

  const fetchReports = () => {
    if (userId) {
      fetch(`${apiUrl}/get-report?user_id=${userId}`)
        .then(response => response.json())
        .then(response => {
          if (response.data) {
            setReports(response.data)
          } else {
            setReports(null)
          }
        })
        .catch(function(err) {
          console.log('Unable to fetch -', err)
        })
    } else {
      setIsLoading(true)
    }
  }

  const fetchChatbotEnabled = () => {
    fetch(`${altApiUrl}/chatbot-check?persona_name=${selectedPersonaName}`)
      .then(response => response.json())
      .then(response => {
        if (response.data) {
          setIsChatEnabled(response.data)
        } else {
          setIsChatEnabled(false)
        }
      })
      .catch(function(err) {
        console.log('Unable to fetch -', err)
      })
  }

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className={clsx('flex-column flex-lg-row-auto mb-10 mb-lg-0', {
        'col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7': isChatEnabled,
        'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12': !isChatEnabled || reportStatus === 'cancelled',
      })}>
        <div className="card card-flush min-height-container alt-bg-gunmetal alt-border-gunmetal-light">
          <div
            className="card-body pt-5"
            id="kt_chat_contacts_body"
            style={{height: `calc(100vh - 112px)`, minHeight: `calc(100vh - 112px)`}}
          >

            {reportStatus === 'cancelled' && (
              <div className="text-center pt-10 alt-white">
                <h2 className="mt-10">Your job has been cancelled.</h2>
                <p>The reason for cancellation is {cancelledNote}. If you think this is a mistake, please contact support at <a href='mailto:info@altsurge.ai'>info@altsurge.ai</a> </p>
              </div>
            )}

            {isLoading && reportStatus !== 'cancelled' && (
              <div className="text-center pt-10 alt-white">
                <Spinner animation="border" role="status" className="d-flex justify-content-center h-80px w-80px loader-align">
                  <span className="sr-only">Loading...</span>
                </Spinner>

                <h2 className="mt-10">We are still generating your report</h2>
                <p>An email will be sent to your registered email when it’s done</p>
              </div>
            )}

            {!isLoading && reportStatus !== 'cancelled' && (
              <div
                className="scroll-y me-n5 pe-5 h-lg-auto"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header"
                data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body"
                data-kt-scroll-offset="0px"
                style={{height: `calc(100vh - 160px)`, minHeight: `calc(100vh - 160px)`}}
              >

                <div className="templates row alt-white">
                  <div className="template_head col-12">
                    {(exportContent && scoreContent) && (
                      <iframe
                        src={`${apiUrl}/export?file_id=${exportContent}&score_file_id=${scoreContent}&embedded=true#navpanes=0`}
                        height="100%"
                        width="100%"
                        style={{height: `calc(100vh - 160px)`}}>
                      </iframe>
                    )}

                    {(exportContent && !scoreContent) && (
                      <iframe src={`${apiUrl}/export?file_id=${exportContent}&embedded=true#navpanes=0`}
                        height="100%"
                        width="100%"
                        style={{height: `calc(100vh - 160px)`}}>
                      </iframe>
                    )}
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
      </div>

      <div className={clsx('flex-lg-row-fluid col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 ms-lg-7 ms-xl-10', {
        'd-none': !isChatEnabled || reportStatus === 'cancelled',
      })}>
        <div className="card min-height-container alt-bg-gunmetal alt-border-gunmetal-light" id="kt_chat_messenger">
          <div className="card-header" id="kt_chat_messenger_header">
            <div className="card-title">
              <div className="symbol-group symbol-hover"></div>
              <div className="symbol symbol-50px symbol-circle" style={{marginLeft: `-15px`}}>
                <img alt={selectedPersonaName} src={selectedPersonaAvatar ? `${publicUrl}/${selectedPersonaAvatar}` : toAbsoluteUrl(`/media/surge/avatar.jpg`)} />
              </div>
              <div className="d-flex justify-content-center flex-column ms-3 me-3">
                <a
                  href="#"
                  className="fs-4 fw-bolder alt-white text-hover-primary me-1 mb-2 lh-1"
                >
                  {selectedPersonaName}
                </a>

                <div className="mb-0 lh-1">
                  <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                  <span className="fs-7 fw-bold alt-white">Active</span>
                </div>
              </div>
            </div>
          </div>

          <ChatInner uploadedFile={uploadedFile} personaName={selectedPersonaName} personaAvatar={selectedPersonaAvatar} />
        </div>
      </div>
    </div>
  )
}

export {Reports}
