import React, {FC} from 'react'

const Step3: FC = () => {
  return (
    <div className='container'>
      <div className="row text-white">
        <div className="col-12 text-center mb-5 mt-5">
          <h2 className="fw-bold fs-2qx fs-lg-2qx mw-1000px text-uppercase m-auto text-white">
            Payment has been <span className="fw-bolder alt-purple">successfully</span> made
          </h2>
          <p className="fs-5 fs-lg-2 mt-4 mt-lg-8 mw-1000px m-auto">
          Thank you for becoming an AltSurge alpha user. Your report is currently being generated. In the meantime, you will receive an email with instructions to complete setting up your account.
          </p>
          <p className="fs-5 fs-lg-2 mt-4 mt-lg-8 mw-1000px m-auto">
            If you encounter any issues or don't receive a report notification within 24 hours, please contact
            support@AltSurge.ai. Note that image-heavy pitch decks may take longer to process. We appreciate your
            patience.
          </p>
        </div>
      </div>
    </div>
  )
}

export {Step3}
