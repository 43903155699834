/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {SidebarMenuReport} from './SidebarMenuReport';
import {dispatchStorageEvent} from '../../../../helpers';
import IPConfig from '../../../../../app/store/IPConfig'

type PropsType = {
  wsString: string
}

const SidebarMenuMain = ({wsString}: PropsType) => {
  const apiUrl = IPConfig();
  const wsUrl = IPConfig("ws");

  const [reports, setReports]: any = useState<[]>([]);
  const [userId, setUserId] = useState<any>(null);
  const [activeReport, setActiveReport] = useState<any>(null);

  useEffect(() => {
    // @ts-ignore
    setUserId(window.localStorage.getItem('user_id'));
  }, []);

  useEffect(() => {
    try {
      fetchReports();
    } catch (e) {
      console.error(e);
    }

    establishWebSocketConnection();
  }, [userId, wsString]);

  const establishWebSocketConnection = () => {
    if (userId && wsString) {
      let wsConnectionUrl = `${wsUrl}/${wsString}?user_id=${userId}`;

      const ws = new WebSocket(wsConnectionUrl);

      ws.onopen = () => console.log('WebSocket connection established.');
      ws.onmessage = (event: any) => {
        const response = JSON.parse(event.data);
        if (response.data) {
          setReports(response.data);
          if (activeReport) {
            setActiveReport(activeReport);
            dispatchReport(response.data[activeReport], activeReport);
          } else {
            setActiveReport(0);
            dispatchReport(response.data[0], 0);
          }
        } else {
          setReports(null)
        }
      };
      ws.onclose = () => console.log('WebSocket connection closed.');
      ws.onerror = (error) => console.error('WebSocket error:', error);
    }
  };

  const onClickReport = (report: any, index: number) => {
    dispatchReport(report, index);
  };

  const dispatchReport = (report: any, index: number) => {
    if (report) {
      setActiveReport(index);
      window.localStorage.setItem('selectedReportName', 'Pitch Readiness Report');
      dispatchStorageEvent('selectedReportName', 'Pitch Readiness Report');
      window.localStorage.setItem('selectedReportContent', '');
      dispatchStorageEvent('selectedReportContent', '');
      window.localStorage.setItem('selectedScoreContent', '');
      dispatchStorageEvent('selectedScoreContent', '');
      window.localStorage.setItem('selectedSourceId', '');
      dispatchStorageEvent('selectedSourceId', '');
      window.localStorage.setItem('selectedJobId', '');
      dispatchStorageEvent('selectedJobId', '');

      if (report.status.name !== "completed") {
        dispatchStorageEvent('setLoadingReport', true);
      }

      if (report.status.name === "cancelled") {
        window.localStorage.setItem('selectedReportStatus', 'cancelled');
        dispatchStorageEvent('selectedReportStatus', 'cancelled');
        window.localStorage.setItem('selectedCancelledNote', report.log);
        dispatchStorageEvent('selectedCancelledNote', report.log);
      } else {
        window.localStorage.setItem('selectedReportStatus', report.status.name);
        dispatchStorageEvent('selectedReportStatus', report.status.name);
      }

      if (report.saved_documents && report.saved_documents.length > 0) {
        if (report.saved_documents[0]) {
          window.localStorage.setItem('selectedReportName', report.saved_documents[0].name);
          dispatchStorageEvent('selectedReportName', report.saved_documents[0].name);
          window.localStorage.setItem('selectedReportContent', report.saved_documents[0].id);
          dispatchStorageEvent('selectedReportContent', report.saved_documents[0].id);
        }

        if (report.saved_documents[1]) {
          window.localStorage.setItem('selectedScoreContent', report.saved_documents[1].id);
          dispatchStorageEvent('selectedScoreContent', report.saved_documents[1].id);
        }
      }

      if (report.source_documents) {
        const sourceDocumentsArray = JSON.parse(report.source_documents);
        if (sourceDocumentsArray && sourceDocumentsArray[0]) {
          window.localStorage.setItem('selectedSourceId', sourceDocumentsArray[0]);
          dispatchStorageEvent('selectedSourceId', sourceDocumentsArray[0]);
        }
      } else {
        window.localStorage.setItem('selectedSourceId', '');
        dispatchStorageEvent('selectedSourceId', '');
      }

      if (report.id) {
        window.localStorage.setItem('selectedJobId', report.id);
        dispatchStorageEvent('selectedJobId', report.id);
      } else {
        window.localStorage.setItem('selectedJobId', '');
        dispatchStorageEvent('selectedJobId', '');
      }

      if (report.input_variables && report.input_variables.agent_persona) {
        window.localStorage.setItem('selectedPersonaName', report.input_variables.agent_persona);
        dispatchStorageEvent('selectedPersonaName', report.input_variables.agent_persona);
      } else {
        window.localStorage.setItem('selectedPersonaName', '');
        dispatchStorageEvent('selectedPersonaName', '');
      }

      if (report.input_variables && report.input_variables.agent_avatar) {
        window.localStorage.setItem('selectedPersonaAvatar', report.input_variables.agent_avatar);
        dispatchStorageEvent('selectedPersonaAvatar', report.input_variables.agent_avatar);
      } else {
        window.localStorage.setItem('selectedPersonaAvatar', '');
        dispatchStorageEvent('selectedPersonaAvatar', '');
      }
    } else {
      dispatchStorageEvent('setLoadingReport', true);
    }
  }

  const fetchReports = () => {
    if (userId) {
      fetch(`${apiUrl}/get-report?user_id=${userId}`)
        .then(response => response.json())
        .then(response => {
          if (response.data) {
            setReports(response.data)
            setActiveReport(0);
            dispatchReport(response.data[0], 0);
          } else {
            setReports(null)
          }
        })
        .catch(function(err) {
          console.log('Unable to fetch -', err)
        })
    }
  }

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section alt-white text-uppercase fs-7 ls-1 fw-bolder">
            Product Requested
          </span>
        </div>
      </div>

      {reports && reports.length > 0 && reports.map((report, index) => (
        <SidebarMenuReport
          key={index}
          to="#"
          icon="clipboard"
          status={`${report.status.name === 'failed' ? 'processing' : report.status.name}`}
          title={`${report.date_created}`}
          reportName={"Pitch Readiness Report"}
          companyName={wsString === "websocket-investor-report" ? (report.input_variables.company_name ? report.input_variables.company_name : "") : ""}
          fontIcon="bi-layers"
          onClick={() => onClickReport(report, index)}
          active={activeReport === index}
        />
      ))}

      {!reports && (
        <div className='text-muted'>
          <p className='ps-4'>Reports are currently pending..</p>
        </div>
      )}
    </>
  )
}

export {SidebarMenuMain}
