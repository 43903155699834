import React, {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

interface Badge {
  color: string
  value: number
}

type Props = {
  to: string
  status: string
  title: string
  reportName: string
  companyName: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  badge?: Badge
  onClick?: () => void;
  active?: boolean;
}

const SidebarMenuReport: FC<Props & WithChildren> = ({
  children,
  to,
  status,
  title,
  reportName,
  companyName,
  icon,
  fontIcon,
  hasBullet = false,
  badge,
  onClick,
  active,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const stripSuffix = (name: string): string => {
    const suffixPattern = /(_2024).*/;
    if (suffixPattern.test(name)) {
      return name.split('_2024')[0];
    }
    const extensionPattern = /\.[^/.]+$/;
    return name.replace(extensionPattern, '');
  };

  const strippedReportName = stripSuffix(reportName);

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link d-block without-sub mb-2', {active: isActive || active})}
        style={{border: '1.5px solid #6e6e6e'}}
        to={to}
        onClick={onClick}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        <div className="fs-5 row mb-1">
          <div className="col-12">
            <span className="menu-title text-muted fs-6 fw-bold">
              {new Date(title).toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              }).replace(/,/g, '')}
            </span>
          </div>
        </div>

        <div className="fs-5 row">
          <div className="col-12">
            <div className="float-start" style={{marginLeft: '-5px'}}>
              {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                <span className='menu-icon'>
                  {' '}
                  <KTIcon iconName={icon} className="fs-2 text-white menu-icon-text" />
                </span>
              )}

              {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
                <i className={clsx('bi fs-3', fontIcon)}></i>
              )}
            </div>

            <div className="float-start">
              <span className="menu-title report-title fw-bold">
                {strippedReportName}
              </span>
            </div>
          </div>
        </div>

        <div className="fs-5 row mb-1">
          <div className="col-12">
            <span className="menu-title">
              {companyName ? ' ' + companyName : ''}
            </span>
          </div>
        </div>

        <div className="fs-5 row">
          <div className="col-12">
            <span className="menu-title">
              Status:

              {/*<span style={{paddingTop: status === "completed" ? "5px" : "7px"}}>*/}
                <KTIcon iconName={status === "completed" ? "check" : "loading"} className="fs-2 ms-2 text-white menu-icon-text" />
              {/*</span>*/}

              <span className={clsx(
                'ms-1',
                {'text-success': status === 'completed'},
                {'text-warning': status !== 'completed'}
              )}>{status === 'completed' ? 'completed' : 'pending'}</span>
            </span>
          </div>
        </div>

        {badge && badge.value > 0 && (
          <span className={`badge bg-${badge.color} py-2 px-3 fw-bold text-white`}>{badge.value.toLocaleString()}</span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuReport}
