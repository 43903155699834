import {SidebarMenuMain} from './SidebarMenuMain'

type PropsType = {
  wsString: string
}

const SidebarMenu = ({wsString}: PropsType) => {
  return (
    <div className="app-sidebar-menu flex-grow-1"
         style={{maxHeight: 'calc(100vh - 160px)'}}>
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
        style={{maxHeight: 'calc(100vh - 160px)'}}
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <SidebarMenuMain wsString={wsString} />
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}