/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers'

type PropsType = {
  buttonUrl: string
}

const SidebarFooter = ({buttonUrl}: PropsType) => {
  return (
    <>
      <div className="app-sidebar-footer mt-auto" id="kt_app_sidebar_footer">
        <div className="flex-column-auto pt-2 pb-6 px-6">
          <a
            href={buttonUrl}
            className="btn btn-flex flex-center btn-custom btn-primary alt-bg-purple overflow-hidden text-nowrap px-0 h-40px w-100"
            title={"GET NEW REPORT"}
          >
            <span className="btn-label text-white">GET NEW REPORT</span>
            <KTIcon iconName="messages" className="btn-icon fs-2 ms-1" />
          </a>
        </div>
      </div>
    </>
  )
}

export {SidebarFooter}
