import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1Investor} from './steps/Step1Investor'
import {Step2Investor} from './steps/Step2Investor'
import {Step3} from './steps/Step3'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {Link} from 'react-router-dom'
import IPConfig from '../../store/IPConfig'
import clsx from 'clsx'

const InvestorWizard: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState<boolean>(false)
  const [stepIncomplete, setStepIncomplete] = useState<boolean>(true)
  const [documentUploaded, setDocumentUploaded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
  const [responseError, setResponseError] = useState('')
  const [responseSuccess, setResponseSuccess] = useState('')
  const [selectedAgentIndex, setSelectedAgentIndex] = useState<number | null>(null)
  const [filesToAdd, setFilesToAdd]: any = useState([])
  const [formikValues, setFormikValues]: any = useState([])
  const [userId, setUserId] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [investorCriteria, setInvestorCriteria] = useState<any>(null);
  const [attemptedNextStep, setAttemptedNextStep] = useState(false);

  const apiUrl = IPConfig('metis')

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === 2)
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setAttemptedNextStep(true);

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      const query = new URLSearchParams(window.location.search)

      if (stepper.current?.currentStepIndex === 1) {
        query.set('registration', 'true')
      }

      if (stepper.current.currentStepIndex === 1) {
        let allFieldsHaveValue = true;
        let skipKeys = ["changepassword", "companyName", "email", "firstname", "password", "acceptTerms"];

        Object.keys(formikValues).forEach(key => {
          if (!skipKeys.includes(key)) {
            const value = formikValues[key];

            if (Array.isArray(value)) {
              if (value.length === 0) {
                allFieldsHaveValue = false;
              }
            } else if (typeof value === 'string') {
              if (value.trim().length === 0) {
                allFieldsHaveValue = false;
              }
            } else if (typeof value === 'boolean') {
              if (!value) {
                allFieldsHaveValue = false;
              }
            }
          }
        });

        if (allFieldsHaveValue) {
          setStepIncomplete(false);
          const newUrl = `${window.location.pathname}?${query.toString()}`;
          window.history.pushState({}, '', newUrl);
          stepper.current.goNext();
          setAttemptedNextStep(false);
        } else {
          setStepIncomplete(true);
        }
      } else if (stepper.current.currentStepIndex === 2) {
        if (typeof formikValues !== 'undefined' && formikValues) {
          setStepIncomplete(false)
          if (documentUploaded) {
            makePayment()
          } else {
            submitRegistration(stepper)
          }
        }
      } else {
        setStepIncomplete(false)
        const newUrl = `${window.location.pathname}?${query.toString()}`;
        window.history.pushState({}, '', newUrl);
        stepper.current.goNext()
      }
    }

    setSubmitButton(stepper.current.currentStepIndex === 2)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    const getCriteria = async () => {
      const criteria = await fetchInvestorCriteria();
      if (criteria) {
        setInvestorCriteria(criteria);
      }
    };
    getCriteria();
  }, []);

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (!stepper.current) {
      return
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      stepper.current?.goto(3)
      try {
        let mandate = window.localStorage.getItem('selectedMandateId')
        let user = window.localStorage.getItem('selectedUserId')
        let document = window.localStorage.getItem('selectedDocumentId')
        let responsePaymentId = window.localStorage.getItem('responsePaymentId')

        const formData = new FormData()
        formData.append('mandate_file_id', mandate ? mandate : '')
        formData.append('pdf_file_id', document ? document : '')
        formData.append('user_id', user ? user : '')
        formData.append('payment_id', responsePaymentId ? responsePaymentId : '')

        fetch(`${apiUrl}/process-investor`, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === 'fail') {
              if (response.message) {
                setResponseError(response.message)
              } else {
                setResponseError('Your file could not be processed')
              }
              setResponseSuccess('')
            }

            if (response.status === 'success') {
              setResponseError('')
              setResponseSuccess('Your file has been submitted for processing')
              window.localStorage.setItem('processedCompanyId', response.data.company_id)
            }

            setLoading(false)
            setLoadingPayment(false)
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
        setResponseError('Your file could not be processed')
        setLoading(false)
        setLoadingPayment(false)
      }
    }
    if (query.get('canceled')) {
      stepper.current?.goto(2)
      setResponseError('Your order has been cancelled')
    }
  }, [])

  const makePayment = () => {
    setLoading(false);
    setLoadingPayment(true);

    let url = `${apiUrl}/create-checkout-session`
    let user = window.localStorage.getItem('selectedUserId')

    const formData = new FormData()
    formData.append('investor', 'true')
    formData.append('registered', 'false')
    formData.append('user_id', user ? user : '')

    fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.payment) {
          window.localStorage.setItem('responsePaymentId', response.payment)
        }
        if (response.data) {
          window.location.href = response.data
        } else {
          // TODO: error
        }
      })
      .catch(function(err) {
        console.log('Unable to fetch -', err)
      })
  }

  const submitRegistration = (stepper) => {
    const file = filesToAdd[0];
    const values = formikValues;

    if (!formikValues.acceptTerms) {
      setResponseError('Terms and conditions must be accepted!')
      return;
    }

    if (!formikValues.email) {
      setResponseError('Please provide an email')
      return;
    }

    if (!formikValues.firstname) {
      setResponseError('Please provide a first name')
      return;
    }

    if (!formikValues.password) {
      setResponseError('Please provide a password')
      return;
    }

    if (!file || !file.name || file.name.length < 1) {
      setResponseError('Please select a file for upload')
      return;
    }

    setLoading(true)
    setLoadingPayment(false)

    try {
      const formData = new FormData();
      formData.append('company_name', values.companyName);
      formData.append('user_name', values.firstname);
      formData.append('email', values.email);
      formData.append('password', values.password);
      formData.append('file', filesToAdd[0]);

      formData.append('industry', values.industry || '');
      formData.append('industry_sub_sector', values.subSector || '');
      formData.append('stage', Array.isArray(values.stage) ? JSON.stringify(values.stage) : '');
      formData.append('technology_preferences', Array.isArray(values.technologyPreferences) ? JSON.stringify(values.technologyPreferences) : '');
      formData.append('business_model', Array.isArray(values.businessModel) ? JSON.stringify(values.businessModel) : '');
      formData.append('geographic_focus', Array.isArray(values.geographicFocus) ? JSON.stringify(values.geographicFocus) : '');
      formData.append('levels_of_revenue_margins', Array.isArray(values.revenueMargins) ? JSON.stringify(values.revenueMargins) : '');
      formData.append('age_of_startup', Array.isArray(values.ageOfStartup) ? JSON.stringify(values.ageOfStartup) : '');

      fetch(`${apiUrl}/register-investor`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 'fail') {
            if (response.message) {
              setResponseError(response.message)
            } else {
              setResponseError('Your registration details are incorrect')
            }
            setResponseSuccess('')
          }

          if (response.status === 'success') {
            setResponseError('')
            setResponseSuccess('You\'ve registered successfully')

            if (response.data.mandate_file_id) {
              setUserId(response.data.mandate_file_id)
              window.localStorage.setItem('selectedMandateId', response.data.mandate_file_id)
            }

            if (response.data.user_id) {
              setUserId(response.data.user_id)
              window.localStorage.setItem('selectedUserId', response.data.user_id)
            }

            if (response.data.file_id) {
              setDocumentId(response.data.file_id)
              window.localStorage.setItem('selectedDocumentId', response.data.file_id)
            }

            setDocumentUploaded(true)
          }

          setLoading(false)
          setLoadingPayment(false)
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
      setResponseError('The registration details are incorrect')
      setLoading(false)
      setLoadingPayment(false)
    }
  }

  const fetchInvestorCriteria = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-investors-criteria`);
      const data = await response.json();
      if (data.status === 'success') {
        return data.data;
      } else {
        console.error('Failed to fetch investor criteria');
      }
    } catch (error) {
      console.error('Error fetching investor criteria:', error);
      return null;
    }
  };

  return (
    <div ref={stepperRef} className="stepper stepper-links row" id="kt_create_account_stepper">
      <div className="col-12 d-flex w-100">
        {/* begin::Logo */}
        <div className={'fs-7qx'}>
          <Link to="/investor">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/alt-logo-dark.png')}
              className="mt-8 ms-6 float-start"
              style={{width: `180px`}}
            />
          </Link>
        </div>
        {/* end::Logo */}
      </div>

      <div className="col-lg-12">
        <ol className="d-block stepper-nav step-indicator mt-6">
          <li className="stepper-entity fs-4 current" data-kt-stepper-element="nav">
            <div className="step"></div>
            <div className="stepper-title text-light caption hidden-xs hidden-sm">
              step <span>1</span>: <span>investor mandate</span>
            </div>
          </li>
          <li className="stepper-entity fs-4" data-kt-stepper-element="nav">
            <div className="step"></div>
            <div className="stepper-title text-light caption hidden-xs hidden-sm">
              step <span>2</span>: <span>upload pitch deck</span>
            </div>
          </li>
          <li className="stepper-entity fs-4" data-kt-stepper-element="nav">
            <div className="step"></div>
            <div className="stepper-title text-light caption hidden-xs hidden-sm">
              step <span>3</span>: <span>make payment</span>
            </div>
          </li>
        </ol>
      </div>

      <div
        className={clsx('col-12', {
          'd-none': !stepIncomplete || !attemptedNextStep,
        })}
      >
        <div className="mt-6 ms-8 me-4 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Please complete the current step before continuing
          </div>
        </div>
      </div>

      <Formik initialValues={initValues} onSubmit={submitStep}>
        {() => (
          <Form className="mx-auto w-100 pt-15 pb-10 ps-12 pe-4" id="kt_create_account_form">
            <div className="current" data-kt-stepper-element="content">
              <Step1Investor
                setStepIncomplete={setStepIncomplete}
                responseError={responseError}
                responseSuccess={responseSuccess}
                setFormikValues={setFormikValues}
                stepper={stepper}
                loading={loading}
                investorCriteria={investorCriteria}
                attemptedNextStep={attemptedNextStep}
              />
            </div>

            <div data-kt-stepper-element="content">
              <Step2Investor
                responseError={responseError}
                responseSuccess={responseSuccess}
                setFormikValues={setFormikValues}
                setFilesToAdd={setFilesToAdd}
                filesToAdd={filesToAdd}
                stepper={stepper}
                loading={loading}
                documentUploaded={documentUploaded}
              />
            </div>

            <div data-kt-stepper-element="content">
              <Step3 />
            </div>

            {(stepper?.current?.currentStepIndex === 1 ||
              stepper?.current?.currentStepIndex === 2) && (
              <div className="d-flex flex-stack pt-15">
                <div className="mr-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg alt-bg-purple text-white me-3"
                    data-kt-stepper-action="previous"
                  >
                    <KTIcon iconName="arrow-left" className="fs-4 me-1" />
                    Back
                  </button>
                </div>

                <div>
                  <button
                    disabled={stepper?.current?.currentStepIndex === 2 && !documentUploaded}
                    type="submit"
                    className="btn btn-lg alt-bg-purple text-white me-3"
                  >
                    {!loadingPayment && (
                      <div>
                        <div className="indicator-label float-start mt-0">
                          <span id="nextButtonLabel">
                            {stepper?.current?.currentStepIndex === 1 && 'Continue'}
                            {stepper?.current?.currentStepIndex === 2 && documentUploaded && 'Make Payment'}
                            {stepper?.current?.currentStepIndex === 2 && !documentUploaded && 'Make Payment'}
                          </span>
                        </div>
                        <div className="float-end" style={{paddingTop: '2px'}}>
                          <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                        </div>
                      </div>
                    )}
                    {loadingPayment && (
                      <span className="indicator-progress" style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {InvestorWizard}
