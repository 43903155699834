/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import IPConfig from '../../../app/store/IPConfig'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import {toAbsoluteUrl, defaultMessages, MessageModel} from '../../helpers'

type Props = {
  personaName?: string
  personaAvatar?: string
  uploadedFile?: any
  isDrawer?: boolean
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({
  isDrawer = false,
  personaName = 'Chatbot',
  personaAvatar = toAbsoluteUrl(`/media/surge/avatar.jpg`),
}) => {
  const [userId, setUserId] = useState<any>(null)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages]: any = useState<MessageModel[]>([])
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)

  const [selectedReportName, setSelectedReportName] = useState(
    () => localStorage.getItem('selectedReportName') || 'Pitch Readiness Report'
  )
  const [selectedJobId, setSelectedJobId]: any = useState(
    () => localStorage.getItem('selectedJobId') || null
  )
  const [selectedPersonaName, setSelectedPersonaName] = useState(
    () => localStorage.getItem('selectedPersonaName') || 'Chatbot'
  )
  const [selectedPersonaAvatar, setSelectedPersonaAvatar] = useState(
    () => localStorage.getItem('selectedPersonaAvatar') || ''
  )

  const scrollRef: any = useRef(null)
  const publicUrl = IPConfig('public')
  const apiUri = IPConfig('metis')

  useEffect(() => {
    // @ts-ignore
    setUserId(window.localStorage.getItem('user_id'))

    setMessages([]);
    setMessage('');

    setSelectedJobId(null);
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedReportName') {
        setSelectedReportName(event.newValue || 'Pitch Readiness Report')
      } else if (event.key === 'selectedJobId') {
        setSelectedJobId(event.newValue || null)
      } else if (event.key === 'selectedPersonaName') {
        setSelectedPersonaName(event.newValue || 'Chatbot')
      } else if (event.key === 'selectedPersonaAvatar') {
        setSelectedPersonaAvatar(event.newValue || '')
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (selectedJobId) {
      // @ts-ignore
      let currentMessages = JSON.parse(window.localStorage.getItem('messages-' + selectedJobId))

      if (currentMessages) {
        setMessages(currentMessages)
      } else {
        setMessages([])
      }
    } else {
      setMessages([])
    }
  }, [selectedJobId])

  useEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      const lastMessageIndex = messages.length - 1;
      const lastMessage = document.getElementById(`message-${lastMessageIndex}`);
      if (lastMessage && messages[lastMessageIndex].sender === 'bot') {
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [messages])

  const handleFormSubmit = (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('job_id', selectedJobId)
    formData.append('user_message', message)

    if (message.trim() !== '') {
      const userMessage = {
        text: message,
        sender: 'user',
      }
      setIsLoadingMessage(true)
      setMessages((prevMessages) => [...prevMessages, userMessage])

      const apiUrl = `${apiUri}/chatbot-chat`

      fetch(apiUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          const botMessage = {
            text: response.message,
            avatar: response.avatar,
            agent_name: response.agent_name,
            knowledge_base_used: response.knowledge_base_used,
            sender: 'bot',
          }

          setMessages((prevMessages) => [...prevMessages, botMessage])
          setIsLoadingMessage(false)
          localStorage.setItem('messages', JSON.stringify(messages))
        })
        .catch((error) => {
          console.error('failed:', error.message)
          setIsLoadingMessage(false)
          const botMessage = {
            text: error.message,
            sender: 'bot',
          }
          setMessages((prevMessages) => [...prevMessages, botMessage])
        })

      // Clear input text
      setMessage('')
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      handleFormSubmit(e)
    }
  }

  const handleTextareaKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      let element: any = document.querySelector('.submit')
      if (element) {
        element.click()
      }
    }
  }

  const handleInputChange = (e) => {
    setMessage(e.target.value)
  }

  useEffect(() => {
    if (selectedJobId) {
      localStorage.setItem('messages-' + selectedJobId, JSON.stringify(messages))
    }
  }, [messages])

  return (
    <div
      className='card-body card-chat'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y scroll-max me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        style={{minHeight: `calc(100vh - 302px)`}}
        ref={scrollRef}
      >
        {messages.map((message, index) => {
          const state = message.sender === 'bot' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.sender === 'bot' ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              id={`message-${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.sender === 'bot' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.sender === 'bot' ? (
                    <>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        style={{border: '4px solid var(--amaranth-purple)'}}
                      >
                        <img
                          alt='Persona'
                          src={
                            personaAvatar
                              ? `${publicUrl}/${personaAvatar}`
                              : toAbsoluteUrl(`/media/surge/avatar.jpg`)
                          }
                        />
                      </div>
                      <div className='ms-3'>
                        <a href='#' className='fs-5 fw-bolder alt-white text-hover-primary me-1'>
                          {personaName ? personaName : 'Chatbot'}
                        </a>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                        <a href='#' className='fs-5 fw-bolder alt-white text-hover-primary ms-1'>
                          You
                        </a>
                      </div>
                      <div
                        className='symbol symbol-35px symbol-circle '
                        style={{border: '4px solid var(--amaranth-purple)'}}
                      >
                        <img alt='Pic' src={toAbsoluteUrl(`/media/surge/avatar.jpg`)} />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-4 pb-1 font-size-all rounded markdown',
                    `bg-light-${state}`,
                    'alt-white alt-bg-gunmetal-light fw-bold mw-lg-400px',
                    `text-${message.sender === 'bot' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                >
                  <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
                </div>

                {message.knowledge_base_used && (
                  <p className='knowledge-base'>Knowledge Base: {message.knowledge_base_used}</p>
                )}
              </div>
            </div>
          )
        })}
        {isLoadingMessage && (
          <>
            <div className='loader'></div>
          </>
        )}
      </div>

      <div
        className='card-footer p-0 pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <form>
          <textarea
            disabled={isLoadingMessage || !selectedJobId}
            className='form-control form-control-flush mb-3 bg-white rounded w-100'
            rows={isDrawer ? 6 : 1}
            data-kt-element='input'
            placeholder={isLoadingMessage ? 'Please wait...' : 'Type a message'}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
              handleInputChange(e)
            }}
            onKeyDown={(e) => {
              onEnterPress(e)
              handleTextareaKeyDown(e)
            }}
          ></textarea>
        </form>
      </div>
    </div>
  )
}

export {ChatInner}
