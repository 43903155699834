/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const InvestorPage = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    const body = document.getElementById('kt_app_body')
    if (root) {
      root.style.height = '100%'
    }
    if (body) {
      body.style.backgroundColor = '#F7F7F7'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
      if (body) {
        body.style.backgroundColor = '#F7F7F7'
      }
    }
  }, [])

  return (
    <>
      {/* HEADER */}
      <header className='container-fluid'>
        <nav className='navbar navbar-expand-md navbar-light mt-6 mb-4'>
          <div className='container-fluid'>
            <a className='navbar-brand' href='/'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/alt-logo-full.png')}
                className='mb-1 float-start'
                style={{width: `340px`}}
              />
            </a>
            <button
              className='navbar-toggler alt-bg-purple py-4 px-4 me-10'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarCollapse'
              aria-controls='navbarCollapse'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon alt-white'></span>
            </button>
            <div className='collapse navbar-collapse ms-sm-10' id='navbarCollapse'>
              <ul className='navbar-nav me-auto text-uppercase fw-bold' style={{fontSize: '18px'}}>
                {/* Navbar */}
              </ul>
              <form className='d-flex' role='search'>
                <a className='navbar-brand' href='/auth/login'>
                  <button
                    className='btn alt-bg-purple alt-white px-20 me-12 text-uppercase'
                    type='button'
                  >
                    Login
                  </button>
                </a>
              </form>
            </div>
          </div>
        </nav>
      </header>
      {/* HEADER */}

      <main>
        {/* HEADING */}
        <div className='container-fluid pt-2 pb-20 z-index-2 position-relative overflow-x-hidden'>
          <div
            className='min-h-900px pt-20 pb-20 text-uppercase'
            style={{
              background: `url(${toAbsoluteUrl('/media/surge/investor/heading.svg')}) top center no-repeat`,
              backgroundSize: 'cover',
              marginRight: '-10px',
              marginLeft: '-10px',
            }}
          >
            <div className='container' style={{marginTop: '220px'}}>
              <div className='float-start mw-500px'>
                <div className='text-light fs-base pb-2 fs-3 w-100'>Alpha Release</div>

                {/* begin::Text */}
                <div className='text-light fs-base pb-2 fw-bolder fs-2qx fs-lg-3qx'>
                  AltSurge - your pitch evaluation co-pilot
                </div>
                {/* end::Text */}
              </div>
            </div>

            <div>
              <img
                className='float-end position-absolute bottom-0 end-25'
                alt='Report'
                src={toAbsoluteUrl('/media/surge/triangles.svg')}
                width='200'
              />
            </div>
          </div>
        </div>
        {/* HEADING */}

        {/* CTA */}
        <div className='container-fluid z-index-2 position-relative'>
          <div className='row mt-20'>
            <div className='col-12 px-10 text-center m-auto' style={{maxWidth: `1200px`}}>
              <p className='fw-bold fs-2qx fs-lg-3qx text-uppercase'>
                As investors, we understand the headache of manually evaluating pitches streaming in from all channels.
              </p>
              <p className='fs-2 mb-10'>
                Time is wasted sifting through ideas that don't align with your fund mandate or investment theses because no one wants to miss a high-potential opportunity. Time is limited for constructive feedback, especially when the startups don't fit your criteria.
              </p>
              <p className='fs-2 mb-10'>
                That’s why we decided to build AltSurge. We needed a quicker and smarter way to pick high-potential startups in a fast-paced environment and provide early-stage startups with tools and advice based on our experience.
              </p>
              <a href={'/auth/investor'}>
                <button
                  type='button'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary alt-bg-purple py-4 px-20 text-center text-uppercase fs-1'
                >
                  Get Started!
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* CTA */}

        {/* REPORT */}
        <div className='container-fluid z-index-2 position-relative overflow-x-hidden'>
          <div
            className='min-h-900px pt-5 pb-5'
          >
            <div className='container-fluid min-h-900px text-white' style={{marginTop: '120px'}}>
              <div className='row'
                style={{
                  marginLeft: '-20px',
                  marginRight: '-20px',
                }}>
                <div className='col-12 col-lg-6 mb-5 mb-lg-0 pt-4 pt-sm-4 pt-md-4 pt-lg-10 pt-xl-20'
                  style={{
                    background: `url(${toAbsoluteUrl('/media/surge/investor/report-bg.svg')}) top left no-repeat`,
                    backgroundSize: 'cover',
                  }}>
                  <h2 className='ps-20 pe-20 text-white fw-bold fs-2qx fs-lg-3qx text-uppercase mt-4 mt-sm-4 mt-md-4 mt-lg-10 mt-xl-20 pt-4 pt-sm-4 pt-md-4 pt-lg-10 pt-xl-20 mw-1000px'>
                    Spot the winners <br /> with <span className='fw-bolder alt-purple'>AI precision</span>
                  </h2>
                  <p className='ps-20 pe-20 fs-2 mt-8 mw-750px'>
                    AltSurge is a powerful AI co-pilot designed to speed up pitch evaluation and augment your innate sense for high-potential ventures. The AI model, trained on screening criteria, pitch deck elements, and investment memos, improves the quality of startups in your pipeline by surfacing high-potential opportunities that align with your provided investment criteria.
                  </p>
                  <p className='ps-20 pe-20 fs-2 mt-4 mw-750px'>
                    AltSurge leverages industry best practices and the collective knowledge of experienced investors and entrepreneurs to evaluate startup potential and score them against your investment criteria. AltSurge also handles all engagement with startup founders, including initial feedback, so you don’t have to.
                  </p>
                </div>
                <div className='col-12 col-lg-6 text-center min-h-900px me-0'
                  style={{
                    background: `url(${toAbsoluteUrl('/media/surge/investor/report.svg')}) top left no-repeat`,
                    backgroundSize: 'cover',
                  }}>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* REPORT */}

        {/* PERSONAS */}
        <div className='container mt-20 pt-20'>
          <div className='row'>
            <div className='col-12 text-center mb-5'>
              <h2 className='fw-bold fs-2qx fs-lg-3qx mw-900px text-uppercase m-auto'>
                AltSurge <span className='fw-bolder alt-purple'>automated</span> pitch evaluation process
              </h2>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona1.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Automated first contact:
              </h2>
              <p className='fs-6 fs-lg-4'>
                AltSurge handles the initial interaction with new startup pitches, ensuring no opportunity is missed.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona2.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Customized filtering:
              </h2>
              <p className='fs-6 fs-lg-4'>
                Our AI filters pitches based on your specific criteria, saving you time  and effort.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona3.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Strategic routing:
              </h2>
              <p className='fs-6 fs-lg-4'>
                Only promising startups that fit your investment criteria make it to  your pipeline.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona4.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Data-driven insights:
              </h2>
              <p className='fs-6 fs-lg-4'>
                Receive a summarized analysis on each pitch, helping you make consistently informed decisions quickly.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona5.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Constructive feedback:
              </h2>
              <p className='fs-6 fs-lg-4'>
                AltSurge provides personalized feedback to founders to refine their pitches and increase their chances of success.
              </p>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 mb-5 mb-lg-10 text-center'>
              <div className='m-auto' style={{width: '200px'}}>
                <img
                  className='bd-placeholder-img img-fluid'
                  alt='Persona'
                  src={toAbsoluteUrl('/media/surge/investor/persona6.svg')}
                />
              </div>
              <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3 text-uppercase'>
                Grow your pipeline:
              </h2>
              <p className='fs-6 fs-lg-4'>
                AltSurge adds qualifying pitches from our network to your pipeline.
              </p>
            </div>
          </div>
        </div>
        {/* PERSONAS */}

        {/* UPLOAD */}
        <div
          className='container-fluid'
          style={{
            background: `url(${toAbsoluteUrl('/media/surge/stripes.svg')}) top left no-repeat`,
            backgroundSize: 'cover',
            paddingTop: '450px',
            marginTop: '-320px',
            minHeight: '1420px',
          }}
        >
          <div className='row'>
            <div className='col-12 text-center'>
              <img
                className='m-auto img-fluid'
                alt='Report'
                src={toAbsoluteUrl('/media/surge/investor/flow.png')}
                style={{
                  maxWidth: '1280px',
                }}
              />
            </div>
            <div className="col-12">
              <div className='card mw-800px p-20 ms-lg-20'>
                <ol className="fs-5 fs-md-3">
                  <li className='pb-1'>Incoming pitches (from Email/Website/CRM/Google Drive/Dropbox/Linkedin/etc.) are queued into AltSurge.</li>
                  <li className='pb-1'>Filtering and Analysis: AI evaluates and categorizes pitches as a strong/weak opportunity and fit/no fit for your investment criteria.</li>
                  <li className='pb-1'>IF Strong pitch, but not a fit for investment criteria - Founder feedback</li>
                  <li className='pb-1'>IF Strong fit, but weak pitch - Founder support to improve pitch content</li>
                  <li className='pb-1'>IF Strong pitch and match for your criteria - the pitch is routed to your pipeline with a short insight summary analysis of the opportunity.</li>
                  <li className='pb-1'>First memo - choose to generate an automated initial review memo for a detailed analysis of the opportunity.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {/* UPLOAD */}

        {/* FAQ */}
        <div className='container pb-5 z-index-3 position-relative mt-20'>
          <div className='row mt-5'>
            <div className='col-12 px-3 px-md-5 m-auto' style={{maxWidth: '1100px'}}>
              <p className='fw-bold fs-2qx fs-lg-3qx text-uppercase text-center mb-5'>
                <span className='fw-bolder alt-purple'>The future</span> of startup acceleration and investment
              </p>
              <div className='mb-4'>
                <div className='pt-4 pb-0'>
                  <div className='row'>
                    <div className='col-12 col-md-6 mb-4'>
                      <div className='mb-4'>
                        <p className='fs-3'>
                          As an AI-powered, high-performance co-pilot, AltSurge bridges the gap between startup founders and early-stage investors. Currently in our Alpha phase, we are actively developing a platform that will transform how startups are financed and managed.
                        </p>
                      </div>
                      <div className='mb-4'>
                        <p className='fs-3'>
                          Our vision includes creating a self-sovereign, open-access performance data marketplace with a hyper-specialized AI reasoning layer. We plan to provide private access to relevant
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 mb-4'>
                      <div className='mb-4'>
                        <p className='fs-3'>
                          benchmarks for 10,000 KPIs across millions of startups. Our platform will enable precise bottom-up market sizing and growth analyses for emerging markets, support the issuance of AI-enabled self-liquidating mezzanine instruments, and offer deep predictive analytics. This will drive actionable insights for management and capital allocation decisions, benefiting startups, investors, and corporate innovators alike.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}

        {/* FOOTING */}
        <div
          className='container-fluid pb-20 z-index-2 position-relative'
          style={{
            background: `url(${toAbsoluteUrl('/media/surge/investor/footing.svg')}) top center no-repeat`,
            backgroundSize: 'cover',
            minHeight: '1200px',
          }}
        >
          <div className='container'>
            <div className='row pt-10 pb-20 mt-20 pt-20'>
              <div style={{marginTop: '320px'}}>
                <div className='float-start mw-600px'>
                  {/* begin::Text */}
                  <div className='text-light fs-2 pb-2 fw-bold fs-2qx fs-lg-3qx text-uppercase'>
                    Partner with AltSurge.
                  </div>
                  {/* end::Text */}

                  {/* begin::Text */}
                  <div className="text-light fs-2 fw-semibold pb-10">
                    By partnering with us, you help AltSurge to build a pipeline of startups that can ultimately use the
                    AltSurge AI platform to optimize their funding opportunities, benchmark their performance, optimize
                    decision-making and improve their chances of success.
                    <br /><br />
                    Partners receive free automated pitch evaluations. We offer customized workflows and flexible levels
                    of integration options, based on your existing workflows and systems.
                    <br /><br />
                    Let’s talk about how we can implement this solution for you.
                  </div>
                  {/* end::Text */}

                  <a href={'/auth/persona'}>
                    <button
                      type="button"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary alt-bg-purple py-4 px-20 text-center text-uppercase fs-1"
                    >
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTING */}

        {/* FOOTER */}
        <footer
          className='container-fluid py-10 alt-bg-dark position-relative'
          style={{marginTop: '-250px'}}
        >
          <div className='container text-dark' style={{marginTop: '200px'}}>
            <div className='row'>
              <div className='col-12 col-md-6 text-center text-md-start'>
                <div className='mw-800px m-auto'>
                  <div className='float-md-end float-lg-end float-xl-end pe-3 mt-1'>
                    <Link to='/'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/surge/logo-footer.png')}
                        className='img-fluid'
                        style={{maxWidth: '380px', height: 'auto'}}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 text-center text-md-start'>
                <div className='pt-4 fs-4'>
                  <p className='text-light fw-semibold mt-8 mb-2'>
                    For more information you can contact me on: <br />llew@altsurge.ai / +31 612 64 74 19 (m)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 text-center'>
            <div className='col-12'>
              <p className='text-light fs-7'>
                © {new Date().getFullYear().toString()} ALTSURGE NETHERLANDS B.V. ALL RIGHTS
                RESERVED.
              </p>
            </div>
          </div>
        </footer>
        {/* FOOTER */}
      </main>
    </>
  )
}

// @ts-ignore
export {InvestorPage}
