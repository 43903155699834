/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ErrorMessage} from 'formik'
import IPConfig from '../../../store/IPConfig'
import {IAgentDetails} from '../../../models/AgentModel'
import clsx from 'clsx'

interface StepProps {
  setStepIncomplete: (flag: boolean) => void
  setSelectedAgentIndex: (index: number) => void
  selectedAgentIndex: number | null
}

const Step1: React.FC<StepProps> = ({
  setStepIncomplete,
  setSelectedAgentIndex,
  selectedAgentIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [agentArray, setAgentArray] = useState<IAgentDetails[]>([])
  const [selectedAgentDescription, setSelectedAgentDescription] = useState<string | null>(null)

  const apiUrl = IPConfig('metis')
  const publicUrl = IPConfig('public')

  useEffect(() => {
    fetchAgents()
  }, [])

  const fetchAgents = () => {
    setIsLoading(true)

    let url = `${apiUrl}/get-persona`

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          setAgentArray(response.data)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })

    setIsLoading(false)
  }

  const handleAgentSelect = (index: number) => {
    setSelectedAgentIndex(index)
    setSelectedAgentDescription(agentArray[index].description)
    window.localStorage.setItem('selectedPersonaId', agentArray[index].id)
    window.localStorage.setItem('wizardPersonaName', agentArray[index].name)
    setStepIncomplete(false)
  }

  return (
    <div className='w-100'>
      <div className='container text-white'>
        <div className='row'>
          <div className='col-12 text-center mb-5 mt-5'>
            <h2 className='fw-bold fs-2qx fs-lg-2qx mw-1000px text-uppercase m-auto text-white'>
              <span className='fw-bolder alt-purple'>Customized feedback</span> in the style of a
              famous investor
            </h2>
            <p className='fs-5 fs-lg-2 mt-4 mt-lg-8 mw-1000px m-auto'>
              The AltSurge AI can give you feedback in the style of your favorite investor. Choose
              the investor voice you resonate with most for custom feedback on your pitch:
            </p>
          </div>
        </div>

        {/* Display AltSurge AI on the top */}
        {agentArray.map(
          (agent, index) =>
            agent.name === 'AltSurge AI' && (
              <div
                key={index}
                id={`agent_${index}`}
                onClick={() => handleAgentSelect(index)}
                className={clsx(
                  'col-12 col-sm-6 col-lg-3 mb-5 mb-lg-10 text-center cursor-pointer persona-entity',
                  {
                    active: selectedAgentIndex === index,
                  }
                )}
              >
                <div className='m-auto' style={{width: '200px'}}>
                  {agent.avatar ? (
                    <div className='z-index-1 symbol-label symbol-circle pe-4'>
                      <img
                        src={publicUrl + agent.avatar}
                        alt={agent.name}
                        className='z-index-1 w-150px rounded-circle'
                      />
                    </div>
                  ) : (
                    <div className='z-index-1 symbol-label symbol-circle pe-4'>
                      <img
                        src={toAbsoluteUrl('/media/avatars/blank.png')}
                        alt={agent.name}
                        className='z-index-1 w-150px rounded-circle'
                      />
                    </div>
                  )}
                </div>
                <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                  {agent.name}
                </h2>
                <p className='fs-6 fs-lg-4'>{agent.description}</p>
              </div>
            )
        )}
        <div className='row mt-10'>
          {agentArray.map(
            (agent, index) =>
              agent.name !== 'AltSurge AI' && (
                <div
                  key={index}
                  id={`agent_${index}`}
                  onClick={() => handleAgentSelect(index)}
                  className={clsx(
                    'col-12 col-sm-6 col-lg-3 mb-5 mb-lg-10 text-center cursor-pointer persona-entity',
                    {
                      active: selectedAgentIndex === index,
                    }
                  )}
                >
                  <div className='m-auto' style={{width: '200px'}}>
                    {agent.avatar ? (
                      <div className='z-index-1 symbol-label symbol-circle pe-4'>
                        <img
                          src={publicUrl + agent.avatar}
                          alt={agent.name}
                          className='z-index-1 w-150px rounded-circle'
                        />
                      </div>
                    ) : (
                      <div className='z-index-1 symbol-label symbol-circle pe-4'>
                        <img
                          src={toAbsoluteUrl('/media/avatars/blank.png')}
                          alt={agent.name}
                          className='z-index-1 w-150px rounded-circle'
                        />
                      </div>
                    )}
                  </div>
                  <h2 className='fw-normal mt-4 mt-lg-8 alt-purple fw-bold fs-5 fs-lg-3'>
                    {agent.name}
                  </h2>
                  <p className='fs-6 fs-lg-4'>{agent.description}</p>
                </div>
              )
          )}

          <div className='text-danger mt-2'>
            <ErrorMessage name='accountType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
