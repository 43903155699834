/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const TermsLayoutAlt = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div className='d-flex w-100 mb-10'>
        {/* begin::Logo */}
        <div className={'w-25 fs-7qx'}>
          <Link to='/'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/alt-logo-light.png')}
              className='mt-4 float-start'
              style={{width: `180px`}}
            />
          </Link>
        </div>
        {/* end::Logo */}

        <div className='w-75'>
          <a href={'/'}>
            <button
              type='button'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary alt-bg-night w-100px mt-6 me-6 text-center float-end'
            >
              Home
            </button>
          </a>
        </div>
      </div>

      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 pb-10 order-1 order-lg-1'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <Outlet />
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}
      </div>

      <div className='d-flex w-100 alt-bg-night position-fixed bottom-0 left-0 py-4'>
        <div className='d-flex w-100 alt-bg-night position-fixed bottom-0 left-0 py-4'>
          <div className='text-dark alt-white order-2 order-md-1 fs-7'>
            <span className='ps-4 alt-white fw-semibold me-1'>
              {new Date().getFullYear().toString()} &copy;
            </span>
            Copyright AltSurge Netherlands - All rights reserved
          </div>
        </div>
      </div>
    </>
  )
}

export {TermsLayoutAlt}
